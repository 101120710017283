import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InternetStatusToast = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.dismiss();
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.warning(
        <div className="user-select-none">
          You are offline. Please check your internet connection.
        </div>,
        {
          autoClose: false,
          toastId: "offline-toast",
          closeButton: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  return null;
};

export default InternetStatusToast;
