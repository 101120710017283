import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { get, put } from "api";
import { toast } from "react-toastify";
import { ICohortLiveSessions, IListCohortLiveSessions } from "types/cohort";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";

interface EditLiveSessionsProps {
  handleCloseEditModal: () => void;
  openEditModal: boolean;
  cohortId: string;
  setCohortLiveSession: React.Dispatch<
    React.SetStateAction<IListCohortLiveSessions[]>
  >;
  liveSessionData: ICohortLiveSessions | undefined;
  loadingLiveSessionData: boolean;
  liveSessionId: number;
  setLiveSessionData: React.Dispatch<
    React.SetStateAction<ICohortLiveSessions | undefined>
  >;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const EditLiveSessions: React.FC<EditLiveSessionsProps> = ({
  cohortId,
  handleCloseEditModal,
  setCohortLiveSession,
  openEditModal,
  liveSessionData,
  loadingLiveSessionData,
  liveSessionId,
  setLiveSessionData,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedLiveSessionData: ICohortLiveSessions = {
      ...liveSessionData!,
      [name]: value,
    };

    setLiveSessionData(updatedLiveSessionData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await put(
        `/cohorts/${cohortId}/live_session/${liveSessionId}`,
        liveSessionData
      );
      handleCloseEditModal();
      setIsSubmitting(false);
      toast.success("Live session updated");
      get(`/cohorts/${cohortId}/live_session`).then((response) => {
        setCohortLiveSession(response.data);
      });
    } catch (err) {
      setIsSubmitting(false);
      toast.error("Error updating live session");
    }
  };

  return (
    <div>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!liveSessionData || loadingLiveSessionData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6rem",
              }}
            >
              <CircularProgress size={30} />
            </div>
          ) : (
            <form className="d-flex flex-column" onSubmit={handleSubmit}>
              <label>
                Title:
                <input
                  className="form-control mb-2"
                  type="text"
                  name="title"
                  required
                  value={liveSessionData.title}
                  onChange={handleChange}
                />
              </label>
              <label className="mb-2">
                Description:
                <textarea
                  className="form-control mb-2"
                  name="description"
                  value={liveSessionData.description}
                  onChange={handleChange}
                />
              </label>
              <label>
                URL:
                <input
                  className="form-control mb-2"
                  type="text"
                  name="url"
                  required
                  value={liveSessionData.url}
                  onChange={handleChange}
                />
              </label>

              <label htmlFor="time">
                Time:
                <input
                  className="form-control"
                  type="datetime-local"
                  id="time"
                  name="time"
                  value={liveSessionData.time}
                  onChange={handleChange}
                />
              </label>

              <label>
                Expiry time:
                <input
                  className="form-control mb-2"
                  type="datetime-local"
                  id="expiry_time"
                  name="expiry_time"
                  value={liveSessionData.expiry_time}
                  onChange={handleChange}
                />
              </label>

              <button
                className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <CircularProgress
                    sx={{ color: white }}
                    size={24}
                    thickness={8}
                  />
                ) : (
                  "Edit Live Session"
                )}
              </button>
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default EditLiveSessions;
