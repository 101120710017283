import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { CommuteOutlined, Logout } from "@mui/icons-material";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  addCohortUrl,
  addContentUrl,
  manageCourseUrl,
  addLessonUrl,
  addProjectUrl,
  addQuizUrl,
  addUserUrl,
  addVoucherUrl,
  listCohortUrl,
  listContentUrl,
  listCourseUrl,
  listLessonUrl,
  listProjectUrl,
  listQuiztUrl,
  listUserUrl,
  listVoucherUrl,
  listPartnerUrl,
  addPartnerUrl,
  dashboardHomeUrl,
  instructors,
} from "config/urls";
import { clearAuthCookies } from "api/cookie";
import { concrete, white } from "config/colors";
import { IAccount } from "types/auth";
import React, { useState } from "react";
import SourceIcon from "@mui/icons-material/Source";
import QuizIcon from "@mui/icons-material/Quiz";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import StyleRoundedIcon from "@mui/icons-material/StyleRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import JoinFullIcon from "@mui/icons-material/JoinFull";

const menuItems = [
  {
    text: "Content",
    icon: <SourceIcon fontSize="small" />,
    sublinks: [
      { url: addContentUrl(), text: "Add Content" },
      { url: listContentUrl(), text: "List Contents" },
    ],
  },
  {
    text: "Quiz",
    icon: <QuizIcon fontSize="small" />,
    sublinks: [
      { url: addQuizUrl(), text: "Add Quiz" },
      { url: listQuiztUrl(), text: "List Quiz" },
    ],
  },
  {
    text: "Project",
    icon: <AccountTreeIcon fontSize="small" />,
    sublinks: [
      { url: addProjectUrl(), text: "Add Project" },
      { url: listProjectUrl(), text: "List Projects" },
    ],
  },
  {
    text: "Lesson",
    icon: <MenuBookIcon fontSize="small" />,
    sublinks: [
      { url: addLessonUrl(), text: "Add Lesson" },
      { url: listLessonUrl(), text: "List Lessons" },
    ],
  },
  {
    text: "Course",
    icon: <SchoolIcon fontSize="small" />,
    sublinks: [
      { url: manageCourseUrl(), text: "Manage Course" },
      { url: listCourseUrl(), text: "List Courses" },
    ],
  },
  {
    text: "User",
    icon: <PersonRoundedIcon fontSize="small" />,
    sublinks: [
      { url: addUserUrl(), text: "Add User" },
      { url: listUserUrl(), text: "List Users" },
    ],
  },
  {
    text: "Voucher",
    icon: <StyleRoundedIcon fontSize="small" />,
    sublinks: [
      { url: addVoucherUrl(), text: "Add Voucher" },
      { url: listVoucherUrl(), text: "List Vouchers" },
    ],
  },
  {
    text: "Cohort",
    icon: <LocalLibraryRoundedIcon fontSize="small" />,
    sublinks: [
      { url: addCohortUrl(), text: "Add Cohort" },
      { url: listCohortUrl(), text: "List Cohorts" },
    ],
  },
  {
    text: "Partners",
    icon: <JoinFullIcon fontSize="small" />,
    sublinks: [
      { url: addPartnerUrl(), text: "Add Partner" },
      { url: listPartnerUrl(), text: "List Partners" },
    ],
  },
  {
    text: "Instructors",
    icon: <CommuteOutlined fontSize="small" />,
    sublinks: [
      { url: instructors(), text: "List Instructors" },
    ],
  },
];

/** Renders the dashboard sidebar drawer menu */
const Sidebar = ({
  onItemClicked,
  account,
}: {
  onItemClicked?: Function;
  account: IAccount;
}) => {
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  const handleMenuClick = (index: number) => {
    const clickedMenuItem = menuItems[index];
    if (clickedMenuItem.sublinks) {
      setExpandedMenu(clickedMenuItem.text);
    } else {
      setExpandedMenu(null);
      if (onItemClicked) {
        onItemClicked();
      }
    }
  };
  return (
    <div>
      <InfoWrapper>
        <Avatar src="/default.png" />
        <Name>{account.name?.toUpperCase()}</Name>
      </InfoWrapper>
      <Divider />

      <List sx={{ pb: 0, pt: 3 }}>
        <SidebarLink to={dashboardHomeUrl()} activeClassName="activeLink">
          <StyledListItemButton>
            <StyledListItemIcon>
              <HomeRoundedIcon fontSize="small" />
            </StyledListItemIcon>
            <ListItemText primary="Home" />
          </StyledListItemButton>
        </SidebarLink>
      </List>

      <React.Fragment>
        <List sx={{ pt: 0, display: "flex", flexDirection: "column" }}>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <StyledListItemButton onClick={() => handleMenuClick(index)}>
                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                <ListItemText primary={item.text} />
              </StyledListItemButton>
              {expandedMenu === item.text && item.sublinks && (
                <List>
                  {item.sublinks.map((sublink, subIndex) => (
                    <SidebarLink
                      key={subIndex}
                      to={sublink.url}
                      activeClassName="activeLink"
                      className="expanded-links"
                    >
                      <StyledListItemButton>
                        <StyledListItemIcon />
                        <ListItemText primary={sublink.text} />
                      </StyledListItemButton>
                    </SidebarLink>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </React.Fragment>
      <Divider />
      <List>
        <StyledListItemButton
          onClick={() => {
            clearAuthCookies();
            window.location.href = "/";
          }}
        >
          <StyledListItemIcon>
            <Logout fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Logout" />
        </StyledListItemButton>
      </List>
    </div>
  );
};

const StyledListItemIcon = styled(ListItemIcon)`
  & {
    color: ${concrete} !important;
  }
`;

const SidebarLink = styled(NavLink)`
  & {
    text-decoration: none !important;
    color: ${concrete};
    display: block;
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  padding: 2px 14px !important;
  border-radius: 8px !important;
`;

const Name = styled.p`
  margin-left: 16px;
  margin-bottom: 0px;
  font-weight: 500;
`;

const InfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px dashed ${white};
  padding: 4px;
`;

export default Sidebar;
