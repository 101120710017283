import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { niagara } from "config/colors";

interface ResetModalProps {
  email: string;
  openResetModal: boolean;
  handleCloseResetModal: () => void;
  handleResetPassword: () => void;
  resetting: boolean;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ResetModal: React.FC<ResetModalProps> = ({
  email,
  openResetModal,
  handleCloseResetModal,
  handleResetPassword,
  resetting,
  newPassword,
  setNewPassword,
}) => {
  return (
    <div>
      <Modal open={openResetModal} onClose={handleCloseResetModal}>
        <div>
          <Box sx={style}>
            <div className="mb-4">
              <div>
                Reset <code>{email}</code> password
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="New Password"
                className="form-control"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
            </div>
            <div className="align-item-center d-flex justify-content-center mt-4 w-100">
              <Button onClick={handleResetPassword}>
                {resetting ? (
                  <CircularProgress
                    sx={{ color: niagara }}
                    size={20}
                    thickness={8}
                  />
                ) : (
                  "Reset"
                )}
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default ResetModal;
