import React, { useState } from "react";
import { useMutation } from "react-query";
import { post } from "api";
import { IAddProjects } from "types/addProject";
import { category } from "types/constant";
import WordEditor from "components/WordEditor";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { uploadFileToS3 } from "hooks/s3Utils";

const initialProject: IAddProjects = {
  _id: "",
  title: "",
  description: "",
  category: "",
  image: "",
  content: "",
  code: {
    html: null,
    css: null,
    javascript: null,
  },
};

const AddProject: React.FC = () => {
  const [formData, setFormData] = useState<IAddProjects>(initialProject);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [isLoadingFileUpload, setLoadingFileUpload] = useState(false);

  const { mutate, isLoading } = useMutation(
    (newProject: IAddProjects) => post("/projects", newProject),
    {
      onSuccess: () => {
        toast("Project added", { type: "success" });
        setFormData(initialProject);
      },
      onError: (error: AxiosError) => {
        toast(error.message, { type: "error" });
      },
    }
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (fileInputRef.current?.files?.[0]) {
        const selectedFile = fileInputRef.current.files[0];
        setLoadingFileUpload(true);
        const uploadedUrl = await uploadFileToS3(selectedFile, "project");
        formData.image = uploadedUrl;
        setLoadingFileUpload(false);
      }
      mutate(formData);
    } catch (err) {
      setLoadingFileUpload(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCodeChange =
    (language: keyof IAddProjects["code"]) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        code: {
          ...prevFormData.code,
          [language]: value,
        },
      }));
    };

  const handleWordEditorChanges = (value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      content: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="form-floating mb-3">
              <input
                type="text"
                name="title"
                onChange={handleChange}
                value={formData.title}
                className="form-control"
                placeholder="Title"
              />
              <label>Title</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                name="description"
                onChange={handleChange}
                value={formData.description}
                className="form-control"
                placeholder="Description"
              />
              <label>Description</label>
            </div>
          </div>

          <div className="col-6">
            <div className="form-floating mb-3">
              <input
                type="file"
                ref={fileInputRef}
                name="image"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                className="form-control"
                placeholder="Image Url"
              />
              <label>Image Url</label>
            </div>

            <div className="form-floating mb-3">
              <select
                className="form-select"
                name="category"
                onChange={handleChange}
                value={formData.category}
              >
                <option value="">Select Category</option>
                {category.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <label>Category</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <p>Codes</p>
            <div className="form-floating mb-3">
              <textarea
                value={formData.code.html || ""}
                onChange={handleCodeChange("html")}
                className="form-control"
                placeholder="Enter HTML Code"
                style={{ height: 100 }}
              ></textarea>
              <label>HTML</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                value={formData.code.css || ""}
                onChange={handleCodeChange("css")}
                className="form-control"
                placeholder="Enter CSS Code"
                style={{ height: 100 }}
              ></textarea>
              <label>CSS</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                value={formData.code.javascript || ""}
                onChange={handleCodeChange("javascript")}
                className="form-control"
                placeholder="Enter JavaScript Code"
                style={{ height: 100 }}
              ></textarea>
              <label>JavaScript</label>
            </div>
          </div>
          <div className="col-6">
            <p>Content</p>
            <WordEditor
              value={formData.content}
              handleOnchange={handleWordEditorChanges}
              height={300}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-primary btn-block d-flex align-items-center justify-content-center"
              onClick={handleSubmit}
            >
              {isLoading || isLoadingFileUpload ? (
                <CircularProgress
                  sx={{ color: white }}
                  size={24}
                  thickness={8}
                />
              ) : (
                "Add Project"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddProject;
