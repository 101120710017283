export const dashboardHomeUrl = () => "/dashboard/home";

export const addContentUrl = () => "/dashboard/add-content";
export const viewContentUrl = (contentId: string | number) =>
  `/dashboard/view-content/${contentId}`;
export const listContentUrl = () => "/dashboard/list-content";

export const addQuizUrl = () => "/dashboard/add-quiz";
export const viewQuiztUrl = (quizId: string | number) =>
  `/dashboard/view-quiz/${quizId}`;
export const listQuiztUrl = () => "/dashboard/list-quiz";

export const addProjectUrl = () => "/dashboard/add-project";
export const viewProjectUrl = (projectId: string | number) =>
  `/dashboard/view-project/${projectId}`;
export const listProjectUrl = () => "/dashboard/list-project";

export const addLessonUrl = () => "/dashboard/add-lesson";
export const viewLessonUrl = (lessonId: string | number) =>
  `/dashboard/view-content/${lessonId}`;
export const listLessonUrl = () => "/dashboard/list-lesson";

export const manageCourseUrl = () => "/dashboard/add-course";
export const viewCourseUrl = (courseId: string | number) =>
  `/dashboard/view-course/${courseId}`;
export const listCourseUrl = () => "/dashboard/list-course";

export const addUserUrl = () => "/dashboard/add-user";
export const listPartnerUrl = () => "/dashboard/partners";
export const addPartnerUrl = () => "/dashboard/add-partner";

export const viewUserUrl = (userId: string | number) =>
  `/dashboard/view-content/${userId}`;
export const listUserUrl = () => "/dashboard/list-user";
export const addVoucherUrl = () => "/dashboard/add-voucher";
export const listVoucherUrl = () => "/dashboard/list-voucher";
export const addCohortUrl = () => "/dashboard/add-cohort";
export const listCohortUrl = () => "/dashboard/list-cohort";

export const instructors = () => "/dashboard/instructors";
