
export const white = "#ffffff";
export const accent = "#ff4f1e";
export const primary = "#0aa89e";
export const error = "#F00";
export const red = "#FF3333";
export const ebb = "#EFEBEB";
export const niagara = "#0AA89E";
export const black = "#000000";
export const gray = "#808080";
export const silver = "#cbc4c4";
export const concrete = "#F2F2F2";
export const elephant = "#143D50";
export const mirage = "#1D2B36";
