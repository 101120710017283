import { get, put } from "api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IListCohorts } from "types/cohort";
import { toast } from "react-toastify";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import CohortStudents from "./CohortStudents";
import ListLiveSessions from "./cohortLiveSession/ListLiveSessions";
import Divider from "./components/Divider";
import CohortCourses from "./CohortCourses";

const EditCohort = () => {
  const [editedCohortData, setEditedCohortData] = useState<IListCohorts | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    get(`/cohorts/${id}`)
      .then((response) => {
        setEditedCohortData(response.data);
        setLoading(false);
      })
      .catch(() => {
        console.log("Error fetching cohorts data");
        setLoading(false);
      });
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if (editedCohortData) {
        await put(`/cohorts/${editedCohortData?._id}`, editedCohortData);
        toast.success("Cohorts edited successfully");
      }
      setIsSubmitting(false);
    } catch (err) {
      toast.error("Error editing cohorts");
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!editedCohortData) {
    return <div>No cohort found for the given id.</div>;
  }

  return (
    <div>
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <label>
          Cohort Name:
          <input
            className="form-control mb-2"
            type="text"
            required
            value={editedCohortData.name}
            onChange={(e) =>
              setEditedCohortData({ ...editedCohortData, name: e.target.value })
            }
          />
        </label>
        <label className="mb-2">
          Status:
          <select
            value={editedCohortData.status}
            onChange={(e) =>
              setEditedCohortData({
                ...editedCohortData,
                status: e.target.value,
              })
            }
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>

        <label>
          Includable:
          <input
            type="checkbox"
            checked={editedCohortData.includable}
            onChange={(e) =>
              setEditedCohortData({
                ...editedCohortData,
                includable: e.target.checked,
              })
            }
          />
        </label>

        <button
          className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {isSubmitting ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            "Edit Cohort"
          )}
        </button>
      </form>
      <div>
        <Divider />
        <ListLiveSessions cohortId={id} />
        <Divider />
        <CohortStudents cohortId={id} />
        <Divider />
        <CohortCourses cohortId={id} />
      </div>
    </div>
  );
};

export default EditCohort;
