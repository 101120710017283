import React, { useEffect, useState } from 'react';
import { get, put } from 'api';
import DataTable from 'react-data-table-component';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import { JSONModal } from './components/JSONModal';

interface Instructor {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  state: string;
  address: string;
  gender: string;
  desiredJobRole: string;
  highestQualification: string;
  yearsOfExperience: string;
  currentJobRole: string;
  availability: string;
  resumeUrl: string;
  status: InstructorStatus;
}

export enum InstructorStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

const Instructors: React.FC = () => {
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedInstructor, setSelectedInstructor] = useState<Instructor | null>(null);

  useEffect(() => {
    get('/instructors')
      .then((response) => {
        setInstructors(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching instructors data:', error);
        setLoading(false);
      });
  }, []);

  const handleStatusClick = (id: string, status: InstructorStatus) => {
    const confirmUpdate = window.confirm(`Confirm setting status to ${status}?`);
    if (confirmUpdate) {
      put(`/instructors/${id}/status`, { status })
        .then(() => {
          return get('/instructors');
        })
        .then((response) => {
          setInstructors(response.data);
        })
        .catch((error) => {
          toast.error('Error updating instructors:', error);
        });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: Instructor) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: Instructor) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row: Instructor) => row.phoneNumber,
      sortable: true,
    },
    {
      name: 'State',
      selector: (row: Instructor) => row.state,
      sortable: true,
    },

    {
      name: 'Resume',
      cell: (row: Instructor) => (
        <a target="_blank" href={row.resumeUrl}>
          View Resume
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: 'Status',
      selector: (row: Instructor) => row.status,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row: Instructor) => (
        <div
          className="btn-group"
          style={{ flexDirection: 'column' }}
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            onClick={() => handleStatusClick(row._id, InstructorStatus.ACCEPTED)}
            className="btn btn-warning"
            style={{ borderRadius: '8px', marginBottom: '8px', marginTop: '8px' }}
          >
            ACCEPT
          </button>

          <button
            type="button"
            onClick={() => handleStatusClick(row._id, InstructorStatus.REJECTED)}
            className="btn btn-danger"
            style={{ borderRadius: '8px', marginBottom: '8px' }}
          >
            REJECT
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: '100%',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: '17px',
      },
    },
    cells: {
      style: {
        fontSize: '17px',
        paddingLeft: '8px',
        paddingRight: '8px',
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'initial',
        div: {
          whiteSpace: 'normal !important',
          overflow: 'visible !important',
          textOverflow: 'initial !important',
        },
      },
    },
    search: {
      style: {
        position: 'relative',
        width: '100%',
      },
    },
    searchInput: {
      style: {
        width: '100%',
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search Instructors..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery('')}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredInstructors = instructors.filter(
    (instructor) =>
      instructor.firstName?.toLowerCase().includes(searchQuery) ||
      instructor.lastName?.toLowerCase().includes(searchQuery) ||
      instructor.phoneNumber?.toLowerCase().includes(searchQuery) ||
      instructor.email?.toLowerCase().includes(searchQuery),
  );

  return (
    <div className="">
      <div className="w-100">
        <h3>Instructors</h3>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable<Instructor>
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredInstructors}
            columns={columns}
            pagination
            paginationPerPage={20}
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover
            onRowClicked={(instructor: Instructor) => setSelectedInstructor(instructor)}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
      <JSONModal
        open={!!selectedInstructor}
        onClose={() => setSelectedInstructor(null)}
        content={selectedInstructor ?? {}}
      />
    </div>
  );
};

export default Instructors;
