import { post } from "api";
import { MutationOptions } from "hooks/types";
import { useMutation } from "react-query";
import {} from "types/auth";
import { IContentData, IContentFormValues } from "types/content";
import { GenericAPIResponse } from "types/generic";

export const useContentMutation = (
  options?: MutationOptions<GenericAPIResponse<IContentData>>
) => {
  return useMutation((value: IContentFormValues) => post(`/contents`, value), {
    ...(options || {}),
  });
};
