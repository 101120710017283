import React, { useState, useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ICohortLiveSessions, IListCohortLiveSessions } from "types/cohort";
import { get, deleteRequest } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import AddLiveSessions from "./AddLiveSessions";
import EditLiveSessions from "./EditLiveSessions";

interface ListLiveSessionsProps {
  cohortId: string;
}

const ListLiveSessions: React.FC<ListLiveSessionsProps> = ({ cohortId }) => {
  const [cohortLiveSession, setCohortLiveSession] = useState<
    IListCohortLiveSessions[]
  >([]);
  const [liveSessionData, setLiveSessionData] = useState<
    ICohortLiveSessions | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [loadingLiveSessionData, setLoadingLiveSessionData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpenStudentModal = () => setOpen(true);
  const handleCloseStudentModal = () => setOpen(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [liveSessionId, setLiveSessionId] = useState(0);

  useEffect(() => {
    get(`/cohorts/${cohortId}/live_session`)
      .then((response) => {
        setCohortLiveSession(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching cohorts live sessions data:", err);
        setLoading(false);
      });
  }, [cohortId]);

  const handleRefreshClick = async () => {
    setSearchQuery("");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const handleEditClick = (id: number) => {
    setLiveSessionId(id);
    setOpenEditModal(true);
    get(`/cohorts/${cohortId}/live_session/${id}`)
      .then((response) => {
        setLiveSessionData(response.data);
        setLoadingLiveSessionData(false);
      })
      .catch(() => {
        console.log("Error fetching live session data");
        setLoadingLiveSessionData(false);
      });
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setLoadingLiveSessionData(true);
  };

  const handleDeleteClick = async (id: number) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this cohort live session?"
    );
    if (confirmDelete) {
      deleteRequest(`/cohorts/${cohortId}/live_session/${id}`);
      await get(`/cohorts/${cohortId}/live_session`).then((response) => {
        setCohortLiveSession(response.data);
      });
    }
  };

  const columns = [
    {
      name: "Title",
      selector: (row: IListCohortLiveSessions) => row.title,
      sortable: true,
      grow: 2,
    },
    {
      name: "time",
      cell: (row: IListCohortLiveSessions) => (
        <span>{format(new Date(row.time), "M/d/yyy h:mm a")}</span>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "expiry_time",
      cell: (row: IListCohortLiveSessions) => (
        <span>{format(new Date(row.expiry_time), "M/d/yyy h:mm a")}</span>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      grow: 3,
      cell: (row: IListCohortLiveSessions) => (
        <div className="btn-group">
          <button
            type="button"
            onClick={() => handleEditClick(row.id)}
            className="btn btn-warning me-2"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={() => handleDeleteClick(row.id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search students in cohort live session..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={handleRefreshClick}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredCohortLiveSession = cohortLiveSession.filter((session) =>
    session.title.toLowerCase().includes(searchQuery)
  );

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div>
      <h4>Live Sessions</h4>
      <div className="w-100 my-2 d-flex justify-content-end">
        <button
          onClick={handleOpenStudentModal}
          className="btn btn-secondary btn-block d-flex align-items-center justify-content-center"
        >
          Schedule Live Session
        </button>
      </div>
      <DataTable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={filteredCohortLiveSession}
        columns={columns}
        pagination
        paginationPerPage={10}
        customStyles={customStyles}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />
      <AddLiveSessions
        cohortId={cohortId}
        handleCloseStudentModal={handleCloseStudentModal}
        open={open}
        setCohortLiveSession={setCohortLiveSession}
      />
      <EditLiveSessions
        cohortId={cohortId}
        handleCloseEditModal={handleCloseEditModal}
        openEditModal={openEditModal}
        liveSessionData={liveSessionData}
        loadingLiveSessionData={loadingLiveSessionData}
        liveSessionId={liveSessionId}
        setLiveSessionData={setLiveSessionData}
        setCohortLiveSession={setCohortLiveSession}
      />
    </div>
  );
};

export default ListLiveSessions;
