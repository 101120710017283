import React, { useEffect, useState } from "react";
import { get } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";

interface Course {
  _id: string;
  slug: string;
  title: string;
  description: string;
  status: string;
}

const ListCourse: React.FC = () => {
  const history = useHistory();

  const [allCourses, setAllCourses] = useState<Course[]>([]);

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get("/admin/courses")
      .then((response) => {
        if (response && Array.isArray(response.data)) {
          setAllCourses(response.data);
          setLoading(false);
        } else {
          console.error("Invalid response data:", response);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
        setLoading(false);
      });
  }, []);

  const handleEditClick = (slug: string) => {
    history.push(`/dashboard/edit-course/${slug}`);
  };

  const handleRowClicked = (row: Course) => {
    history.push(`/dashboard/edit-course/${row.slug}`);
  };

  // function may still be used

  // const archiveCourse = async (id: string) => {
  //   try {
  //     const updatedPublishedCourses = publishedCourses.map((course) =>
  //       course._id === id ? { ...course, status: "archived" } : course
  //     );

  //     const courseToArchive = publishedCourses.find(
  //       (course) => course._id === id
  //     );
  //     if (courseToArchive) {
  //       courseToArchive.status = "archived";
  //     }

  //     const updatedArchivedCourses = archivedCourses.map((course) =>
  //       course._id === id ? { ...course, status: "archived" } : course
  //     );

  //     await toast.promise(put(`/course/${id}`, { status: "archived" }), {
  //       pending: "Archiving course...",
  //       success: "Archived course successfully!",
  //       error: "Failed to archive course.",
  //     });

  //     setPublishedCourse(updatedPublishedCourses);
  //     setArchivedCourses(updatedArchivedCourses);
  //   } catch (error) {
  //     console.error("Error archiving course:", error);
  //   }
  // };

  // const publishCourse = async (id: string) => {
  //   try {
  //     const updatedPublishedCourses = publishedCourses.map((course) =>
  //       course._id === id ? { ...course, status: "published" } : course
  //     );

  //     const courseToPublish = archivedCourses.find(
  //       (course) => course._id === id
  //     );
  //     if (courseToPublish) {
  //       courseToPublish.status = "published";
  //     }

  //     const updatedArchivedCourses = archivedCourses.map((course) =>
  //       course._id === id ? { ...course, status: "published" } : course
  //     );

  //     await toast.promise(put(`/course/${id}`, { status: "published" }), {
  //       pending: "Restoring course...",
  //       success: "Published course successfully!",
  //       error: "Failed to publish course.",
  //     });

  //     setPublishedCourse(updatedPublishedCourses);
  //     setArchivedCourses(updatedArchivedCourses);
  //   } catch (error) {
  //     console.error("Error restoring course:", error);
  //   }
  // };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const filteredCourses = allCourses.filter(
    (course) =>
      course.title.toLowerCase().includes(searchQuery) ||
      course.description.toLowerCase().includes(searchQuery) ||
      course.status.toLowerCase().includes(searchQuery)
  );

  const columns = [
    {
      name: "Title",
      selector: (row: Course) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: Course) => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: Course) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: Course) => (
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={() => handleEditClick(row.slug)}
            className="btn btn-warning me-2"
          >
            Edit
          </button>

          {/* Button may still be used */}
          {/* <button
            type="button"
            onClick={() =>
              row.status === "published"
                ? archiveCourse(row._id)
                : publishCourse(row._id)
            }
            className={`btn ${
              row.status === "published" ? "btn-danger" : "btn-success"
            }`}
          >
            {row.status === "published" ? "Archive" : "Publish"}
          </button> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search course..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  return (
    <div className="">
      <div className="w-100">
        <h3>Course List</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredCourses}
            columns={columns}
            pagination
            paginationPerPage={20}
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
            highlightOnHover
            pointerOnHover
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListCourse;
