/**
 * A re-usable JSON preview modal for dynamic content
 */

import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import ModalContainer from 'components/ModalContainer';
import { useMemo } from 'react';
import { isUrl } from 'utils/url';

interface JSONModalProps {
  content: Record<string, any>;
  open: boolean;
  onClose: () => void;
  title?: string;
  width?: number;
}

interface Item {
  key: string;
  value: any;
  type: 'link' | 'text';
}

export const JSONModal = (props: JSONModalProps) => {
  const { content, open, onClose, title = 'JSON Preview', width = 800 } = props;

  const items: Item[] = useMemo(() => {
    return Object.keys(content).map((key) => ({
      key,
      value: content[key],
      type: isUrl(content[key]) ? 'link' : 'text',
    }));
  }, [content]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer title={title} handleClose={onClose} style={{ width }}>
        {items.map((item) => {
          const nodes = [
            <Typography variant="body2" marginTop={2}>
              {item.key}
            </Typography>,
          ];
          if (item.type === 'link') {
            nodes.push(
              <a target="_blank" href={item.value}>
                {item.key}
              </a>,
            );
          } else {
            nodes.push(<input type="text" className="form-control" disabled value={item.value} />);
          }
          return nodes;
        })}
      </ModalContainer>
    </Modal>
  );
};
