import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import RefreshIcon from "@mui/icons-material/Refresh";
import { get } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { ICourseData } from "types/course";

interface CohortCoursesProp {
  cohortId: string;
}

const CohortCourses: React.FC<CohortCoursesProp> = ({ cohortId }) => {
  const [coursesData, setCoursesData] = useState<ICourseData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get(`/cohorts/${cohortId}`)
      .then((response) => {
        if (response.data && Array.isArray(response.data.courses)) {
          const validCourses = response.data.courses.filter(
            (course: ICourseData) => course !== null
          );
          setCoursesData(validCourses);
        } else {
          console.error("Invalid response structure:", response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching cohorts data:", err);
        setLoading(false);
      });
  }, [cohortId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const handleRefreshClick = async () => {
    setSearchQuery("");
  };

  const columns = [
    {
      name: "Title",
      selector: (row: ICourseData) => row.title,
      sortable: true,
      grow: 3,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search course in cohort..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={handleRefreshClick}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredCoursesData = coursesData.filter((course) =>
    course.title.toLowerCase().includes(searchQuery)
  );

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div>
      <h4>Cohort Courses</h4>
      <DataTable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={filteredCoursesData || []}
        columns={columns}
        pagination
        paginationPerPage={10}
        customStyles={customStyles}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />
    </div>
  );
};

export default CohortCourses;
