import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DataTable from "react-data-table-component";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IListLesson } from "types/addLesson";
import { SortableItemProps } from "@thaddeusjiang/react-sortable-list";
import { IconButton } from "@material-ui/core";

interface AddLessonModalProps {
  lessonsData: IListLesson[];
  handleCloseLessonModal: () => void;
  openLessonModal: boolean;
  setSelectedLessons: (lessons: SortableItemProps[]) => void;
  selectedLessons: SortableItemProps[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const AddLessonModal: React.FC<AddLessonModalProps> = ({
  handleCloseLessonModal,
  lessonsData,
  openLessonModal,
  setSelectedLessons,
  selectedLessons,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const handleAddLesson = (lesson: IListLesson) => {
    if (selectedLessons.some((prevLesson) => prevLesson.id === lesson._id)) {
      return null;
    } else {
      const updatedLessons = [
        ...selectedLessons,
        {
          id: lesson._id,
          name: lesson.title,
        },
      ];
      setSelectedLessons(updatedLessons);
    }
  };

  const handleRemoveLesson = (lessonId: string) => {
    const updatedLessons = selectedLessons.filter(
      (lesson) => lesson.id !== lessonId
    );
    setSelectedLessons(updatedLessons);
  };

  const columns = [
    {
      name: "Title",
      selector: (row: IListLesson) => row.title,
      sortable: true,
      grow: 5,
    },
    {
      name: "Action",
      cell: (row: IListLesson) => (
        <div className="btn-group">
          {selectedLessons.some((prevLesson) => prevLesson.id === row._id) ? (
            <div className="d-flex">
              <IconButton
                className="p-2"
                onClick={() => handleRemoveLesson(row._id)}
              >
                <CloseIcon style={{ color: "red" }} />
              </IconButton>
            </div>
          ) : (
            <button
              type="button"
              onClick={() => handleAddLesson(row)}
              className="btn btn-success"
            >
              Add
            </button>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
    searchInput: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search lesson..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredLesson = lessonsData.filter((lesson) => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const title = lesson.title ? lesson.title.toLowerCase() : "";

    return title.includes(lowerCaseSearchQuery) || "";
  });

  return (
    <div>
      <Modal
        open={openLessonModal}
        onClose={handleCloseLessonModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredLesson}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddLessonModal;
