import React, { useEffect, useState } from "react";
import { get, put } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "date-fns";
import { IconButton } from "@mui/material";
import ActionOption from "./ActionOption";
import ResetModal from "./ResetModal";
import { toast } from "react-toastify";
import SubscribeUser from "./SubscribeUser";

interface IUserData {
  avatar: null;
  _id: string;
  email: string;
  phoneNumber: string;
  lastLogin: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

const ListUser: React.FC = () => {
  const [user, setUser] = useState<IUserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [gottenEmail, setGottenEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [openActionOption, setOpenActionOption] = useState<null | HTMLElement>(
    null
  );

  const handleClickOption = (
    event: React.MouseEvent<HTMLElement>,
    email: string
  ) => {
    setOpenActionOption(event.currentTarget);
    setGottenEmail(email);
  };

  const [openResetModal, setResetModal] = useState(false);
  const [openSubscribeUser, setOpenSubscribeUser] = useState(false);

  const handleOpenResetModal = () => {
    setResetModal(true);
    setOpenActionOption(null);
  };
  const handleCloseResetModal = () => {
    setResetModal(false);
    setNewPassword("");
  };

  const handleOpenSubscribeUserModal = () => {
    setOpenSubscribeUser(true);
  };

  const handleCloseSubscribeUserModal = () => {
    setOpenSubscribeUser(false);
  };

  const handleResetPassword = async () => {
    if (newPassword.trim() === "") {
      toast.error("Please enter a new password.");
      return;
    }
    const requestBody = {
      identifier: gottenEmail,
      newPassword: newPassword,
    };
    setResetting(true);
    try {
      await put("/admin/student/reset_password", requestBody);
      toast.success("Password reset successful.");
      setNewPassword("");
    } catch (error) {
      toast.error(
        "An error occurred while resetting the password. Please try again later."
      );
    } finally {
      setResetting(false);
      handleCloseResetModal();
    }
  };

  useEffect(() => {
    get("/admin/student")
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Email",
      selector: (row: IUserData) => row.email,
      sortable: true,
      grow: 2,
    },
    {
      name: "Phone",
      selector: (row: IUserData) => row.phoneNumber,
      sortable: true,
      grow: 2,
    },
    {
      name: "Role",
      selector: (row: IUserData) => row.role,
      sortable: true,
      grow: 2,
    },
    {
      name: "Created",
      cell: (row: IUserData) => (
        <span>{format(new Date(row.createdAt), "M/d/yyy")}</span>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Last login",
      cell: (row: IUserData) => (
        <span>{format(new Date(row.lastLogin), "M/d/yyy h:mm a")}</span>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      cell: (row: IUserData) => (
        <>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleClickOption(event, row.email)
            }
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <ActionOption
            openActionOption={openActionOption}
            setOpenActionOption={setOpenActionOption}
            handleOpenResetModal={handleOpenResetModal}
            handleOpenSubscribeUserModal={handleOpenSubscribeUserModal}
          />
          <ResetModal
            email={gottenEmail}
            openResetModal={openResetModal}
            handleCloseResetModal={handleCloseResetModal}
            handleResetPassword={handleResetPassword}
            resetting={resetting}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
          />
          <SubscribeUser
            handleCloseSubscribeUserModal={handleCloseSubscribeUserModal}
            openSubscribeUser={openSubscribeUser}
            userId={row._id}
          />
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search user..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredUserData = user.filter(
    (userFilteredData) =>
      userFilteredData.email.toLowerCase().includes(searchQuery) ||
      userFilteredData.role.toLowerCase().includes(searchQuery) ||
      userFilteredData.phoneNumber.toLowerCase().includes(searchQuery)
  );

  return (
    <div>
      <div className="w-100">
        <h3>Users List</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredUserData}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListUser;
