import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Sidebar from "./Sidebar";
import { appName } from "config/copy";
import { IAccount } from "types/auth";
import { concrete, mirage } from "config/colors";

export type IDashboardLayoutProps = {
  children: React.ReactNode;
  drawerWidth?: number;
  account: IAccount;
};

const defaultDrawerWidth = 260;

const DashboardLayout = ({
  children,
  drawerWidth,
  account,
}: IDashboardLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen((isOpen) => !isOpen);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  drawerWidth = drawerWidth || defaultDrawerWidth;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          boxShadow: "0 3px 6px rgb(0 0 0 / 12%)",
        }}
      >
        <Toolbar
          sx={{
            background: "#fff",
            color: "#000",
            elevation: 0,
            minHeight: "64px !important",
          }}
        >
          {isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            {appName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {isSmallScreen && (
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                p: 2,
                background: mirage,
                color: concrete,
              },
            }}
          >
            <Sidebar
              account={account}
              onItemClicked={() => setIsDrawerOpen(false)}
            />
          </Drawer>
        )}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: mirage,
              color: concrete,
              p: 2,
            },
          }}
          open
        >
          <Sidebar account={account} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 6,
          background: "#f5f5ff",
          minHeight: "100vh",
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            borderRadius: 4,
            background: "#ffffff",
            mt: 3,
            mb: 3,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
