import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const uploadFileToS3 = (
  file: File,
  directory: string,
  progressCallback?: (progress: number) => void
): Promise<string> => {
  const fileName = `${uuidv4()}_${file.name}`;

  const params: AWS.S3.PutObjectRequest = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || "",
    Key: `${process.env.REACT_APP_AWS_DIR_NAME}/${directory}/${fileName}`,
    Body: file,
    ACL: "public-read",
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, { partSize: 5 * 1024 * 1024 })
      .on("httpUploadProgress", (progress) => {
        const uploadedBytes = progress.loaded;
        const totalBytes = progress.total;
        const uploadProgress = (uploadedBytes / totalBytes) * 100;
        if (progressCallback) {
          progressCallback(uploadProgress);
        }
      })
      .send((err: Error, data: AWS.S3.ManagedUpload.SendData) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
  });
};

export { uploadFileToS3 };
