import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { IEditLesson, Lessons } from "types/addLesson";
import { toast } from "react-toastify";
import { white } from "config/colors";
import { lessonType } from "types/constant";

const EditLesson: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [lesson, setLesson] = useState<IEditLesson | null>(null);
  const [loadingLesson, setLoadingLesson] = useState(true);
  const [editingLesson, setEditingLesson] = useState(false);
  const [quizData, setQuizData] = useState<Lessons[]>([]);
  const [contentsData, setContentsData] = useState<Lessons[]>([]);
  const [projectsData, setProjectsData] = useState<Lessons[]>([]);

  useEffect(() => {
    get(`/lessons/${slug}`)
      .then((response) => {
        setLesson(response);
        setLoadingLesson(false);
      })
      .catch((error) => {
        console.error("Error fetching lesson data:", error);
        setLoadingLesson(false);
      });
  }, [slug]);

  useEffect(() => {
    get("admin/quiz")
      .then((response) => {
        setQuizData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching quiz data:", error);
      });
  }, []);

  useEffect(() => {
    get("/contents")
      .then((response) => {
        setContentsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching content data:", error);
      });
  }, []);

  useEffect(() => {
    get("/projects")
      .then((response) => {
        setProjectsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, []);

  if (loadingLesson) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!lesson) {
    return <div>No lesson found for the given SLUG.</div>;
  }

  const getTypeReferenceOptions = () => {
    let referenceData: { slug: string; _id: string; title: string }[] = [];
    switch (lesson.type) {
      case "quiz":
        referenceData = quizData;
        break;
      case "content":
        referenceData = contentsData;
        break;
      case "project":
        referenceData = projectsData;
        break;
      default:
        return null;
    }
    const filteredOptions = referenceData.filter((data) =>
      data.title.toLowerCase().includes(lesson.type_reference_id.toLowerCase())
    );

    return (
      <>
        {filteredOptions.map((data) => (
          <option key={data._id} value={data._id}>
            {data.title}
          </option>
        ))}
      </>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEditingLesson(true);
    try {
      await put(`/lessons/${lesson._id}`, lesson);
      toast.success("Lesson updated successfully");
      setEditingLesson(false);
    } catch (error: any) {
      toast.error(`Error updating lesson: ${error}`);
      setEditingLesson(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={lesson.title}
          onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          className="form-control"
          value={lesson.description}
          onChange={(e) =>
            setLesson({ ...lesson, description: e.target.value })
          }
        />
      </div>
      <div className="form-floating mb-3">
        <select
          className="form-select"
          name="type"
          onChange={(e) => setLesson({ ...lesson, type: e.target.value })}
          value={lesson.type}
        >
          <option value="">Select Type</option>
          {lessonType.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        <label>Type</label>
      </div>
      <div className="form-floating mb-3">
        <input
          list="type_reference_id_list"
          className="form-control"
          name="type_reference_id"
          onChange={(e) =>
            setLesson({ ...lesson, type_reference_id: e.target.value })
          }
          value={lesson.type_reference_id}
          autoComplete="off"
        />
        <label>
          Select{" "}
          {lesson.type === "quiz"
            ? "Quiz"
            : lesson.type === "content"
            ? "Content"
            : "Project"}
        </label>
        <datalist id="type_reference_id_list">
          {getTypeReferenceOptions()}
        </datalist>
      </div>
      <button
        className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center mt-2"
        onClick={handleSubmit}
      >
        {editingLesson ? (
          <CircularProgress sx={{ color: white }} size={24} thickness={8} />
        ) : (
          "Edit Lesson"
        )}
      </button>
    </form>
  );
};

export default EditLesson;
