import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DashboardRoute from "./DashboardRoute";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "views/auth/Signin";
import { Box } from "@mui/material";
import Index from "../views/index/Index";
import NotFound from "components/NotFound";
import AppBar from "components/Appbar";
import Appbar from "components/Appbar";
import { getToken } from "api/cookie";

/** Setups up the application route */

// Helper function to check if the user is logged in
const isAuthenticated = () => {
  const token = getToken();
  return !!token;
};

// ProtectedRoute is the HOC to guard the dashboard route
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
};

const AppRoute = () => {
  return (
    <div id="app-container">
      <Appbar />
      <Switch>
        <Route path="/" exact render={Index} />
        <Route path="/signin" exact component={SignIn} />

        <Route path="/homepage" exact component={Index} />

        <ProtectedRoute path="/dashboard" component={DashboardRoute} />

        <Route
          path="*"
          render={() => (
            <Box>
              <AppBar />
              <NotFound />{" "}
            </Box>
          )}
        />
      </Switch>
      <ToastContainer />
    </div>
  );
};

export default AppRoute;
