import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { category, type } from "../../types/constant";
import { uploadFileToS3 } from "hooks/s3Utils";
import { white } from "config/colors";
import { toast } from "react-toastify";
import MDEditor from "@uiw/react-md-editor";

interface Content {
  _id: string;
  title: string;
  description: string;
  type: string;
  category: string;
  content: string | undefined;
  contentUrl?: string;
  code: {
    html: string;
    css: string;
    javascript: string;
  };
}

const EditContent: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentContentImageUrl, setCurrentContentImageUrl] = useState<
    string | undefined
  >();

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const contentImageUrl = content?.contentUrl;
    setCurrentContentImageUrl(contentImageUrl);
  }, [content]);

  useEffect(() => {
    get(`/contents/${slug}`)
      .then((response) => {
        setContent(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching content data:", error);
        setLoading(false);
      });
  }, [slug]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!content) {
        console.error("Content is null");
        return;
      }

      if (!content.contentUrl) {
        delete content.contentUrl;
      }

      if (
        (content.type === "image" || content.type === "video") &&
        fileInputRef.current?.files?.[0]
      ) {
        const selectedFile = fileInputRef.current.files[0];
        const uploadedUrl = await uploadFileToS3(selectedFile, "content");
        content.contentUrl = uploadedUrl;
        setCurrentContentImageUrl(uploadedUrl);
      }

      await put(`/contents/${content._id}`, content);
      toast.success("Content updated successfully");
      setIsSubmitting(false);
    } catch (error) {
      toast.error("Error updating content");
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!content) {
    return <div>No content found for the given id.</div>;
  }

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="title"
                  onChange={(e) =>
                    setContent({ ...content, title: e.target.value })
                  }
                  value={content.title}
                  className="form-control"
                  placeholder="Title"
                />
                <label>Title</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  name="description"
                  onChange={(e) =>
                    setContent({ ...content, description: e.target.value })
                  }
                  value={content.description}
                  className="form-control"
                  placeholder="Description"
                />
                <label>Description</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  name="type"
                  onChange={(e) =>
                    setContent({ ...content, type: e.target.value })
                  }
                  value={content.type}
                >
                  <option value="">Select Type</option>
                  {type.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <label>Type</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  name="category"
                  onChange={(e) =>
                    setContent({ ...content, category: e.target.value })
                  }
                  value={content.category}
                >
                  <option value="">Select Category</option>
                  {category.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}{" "}
                </select>
                <label>Category</label>
              </div>
            </div>
            <div className="col-6">
              {content.type === "image" || content.type === "video" ? (
                <div className="form-floating mb-3">
                  <input
                    type="file"
                    name="contentUrl"
                    ref={fileInputRef}
                    onChange={(e) =>
                      setContent({ ...content, contentUrl: e.target.value })
                    }
                    className="form-control"
                    placeholder={`Upload ${
                      content.type === "image" ? "Image" : "Video"
                    }`}
                  />
                  <label>{`Upload ${
                    content.type === "image" ? "Image" : "Video"
                  }`}</label>
                  {content.type === "video" && (
                    <video
                      style={{ width: "100%" }}
                      src={currentContentImageUrl}
                      controls
                    />
                  )}
                  {content.type === "image" && (
                    <div
                      className="rounded m-1 d-flex align-items-center justify-content-center"
                      style={{
                        height: "300px",
                        width: "100%",
                        background: "gray",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${currentContentImageUrl})`,
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="">
                  <p>Codes</p>
                  <div className="form-floating mb-3">
                    <textarea
                      value={content.code.html}
                      onChange={(e) =>
                        setContent({
                          ...content,
                          code: { ...content.code, html: e.target.value },
                        })
                      }
                      className="form-control"
                      placeholder="Enter HTML Code"
                      style={{ height: 100 }}
                    ></textarea>
                    <label>HTML</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      value={content.code.css}
                      onChange={(e) =>
                        setContent({
                          ...content,
                          code: { ...content.code, css: e.target.value },
                        })
                      }
                      className="form-control"
                      placeholder="Enter CSS Code"
                      style={{ height: 100 }}
                    ></textarea>
                    <label>CSS</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      value={content.code.javascript}
                      onChange={(e) =>
                        setContent({
                          ...content,
                          code: { ...content.code, javascript: e.target.value },
                        })
                      }
                      className="form-control"
                      placeholder="Enter JavaScript Code"
                      style={{ height: 100 }}
                    ></textarea>
                    <label>JavaScript</label>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <p>Content</p>
              <MDEditor
                value={content.content || ""}
                onChange={(e) => setContent({ ...content, content: e })}
              />
            </div>
          </div>

          <div className="row">
            <div style={{ height: "70px" }} />
            <div className="">
              <button
                onClick={handleSubmit}
                className="btn btn-primary btn-block d-flex align-items-center justify-content-center"
              >
                {isSubmitting ? (
                  <CircularProgress
                    sx={{ color: white }}
                    size={24}
                    thickness={8}
                  />
                ) : (
                  "Edit content"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditContent;
