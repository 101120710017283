import React, { useState, useEffect } from "react";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { IListVouchers } from "types/voucher";
import { get, put } from "api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const EditVoucher = () => {
  const history = useHistory();
  const [editedFormData, setEditedFormData] = useState<IListVouchers | null>(
    null
  );
  const { code } = useParams<{ code: string }>();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    get(`/voucher/${code}`)
      .then((response) => {
        const formattedExpireAt = new Date(response.data.expireAt)
          .toISOString()
          .slice(0, 16)
          .replace("T", " ");
        response.data.expireAt = formattedExpireAt;
        setEditedFormData(response.data);
        setLoading(false);
      })
      .catch(() => {
        console.log("Error fetching voucher data");
        setLoading(false);
      });
  }, [code]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = name === "discount" ? parseFloat(value) : value;

    setEditedFormData(
      (prevData: IListVouchers | null) =>
        ({
          ...prevData,
          [name]: numericValue,
        } as IListVouchers | null)
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if (editedFormData) {
        await put(`/voucher/${editedFormData?._id}`, editedFormData);
        toast.success("Voucher edited successfully");
        history.push("/dashboard/list-voucher");
      }
      setIsSubmitting(false);
    } catch (err) {
      toast.error("Error editing voucher");
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!editedFormData) {
    return (
      <div>No voucher found for the given code or voucher has expired.</div>
    );
  }

  return (
    <div>
      <h2>Edit Voucher</h2>
      <form>
        <div>
          <label htmlFor="code">Code:</label>
          <input
            className="form-control"
            type="text"
            id="code"
            name="code"
            value={editedFormData.code}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="discount">Discount:</label>
          <input
            className="form-control"
            type="number"
            id="discount"
            name="discount"
            value={editedFormData.discount}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="limit">Limit:</label>
          <input
            className="form-control"
            type="number"
            id="limit"
            name="limit"
            value={editedFormData.limit}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="expireAt">Expire At:</label>
          <input
            className="form-control"
            type="datetime-local"
            id="expireAt"
            name="expireAt"
            value={editedFormData.expireAt}
            onChange={handleChange}
          />
        </div>
        <button
          className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {isSubmitting ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            "Edit Voucher"
          )}
        </button>
      </form>
    </div>
  );
};

export default EditVoucher;
