import React, { useEffect, useState } from "react";
import { get, deleteRequest } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IListCohortStudent } from "types/cohort";
import AddStudent from "./AddStudent";
import { toast } from "react-toastify";
import { handleNullUndefined } from "hooks/handleNullUndefined";

interface CohortStudentsProp {
  cohortId: string;
}

const CohortStudents: React.FC<CohortStudentsProp> = ({ cohortId }) => {
  const [cohortStudent, setCohortStudent] = useState<IListCohortStudent[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get(`/cohorts/${cohortId}/students`)
      .then((response) => {
        setCohortStudent(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching cohorts students data:", err);
        setLoading(false);
      });
  }, [cohortId]);

  const handleRemoveClick = (memberId: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to remove this student from this cohort?"
    );
    if (confirmDelete) {
      toast.promise(
        deleteRequest(`/cohorts/students/${memberId}`)
          .then(() => {
            return get(`/cohorts/${cohortId}/students`);
          })
          .then((response) => {
            setCohortStudent(response);
          }),
        {
          pending: "Removing student...",
          success: "Student removed successfully",
          error: "Error removing student to cohort",
        }
      );
    }
  };

  const handleRefreshClick = async () => {
    setSearchQuery("");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpenStudentModal = () => setOpen(true);
  const handleCloseStudentModal = () => setOpen(false);

  const columns = [
    {
      name: "Name",
      selector: (row: IListCohortStudent) =>
        `${handleNullUndefined(row.user.firstName)} ${handleNullUndefined(
          row.user.lastName
        )}`,
      sortable: true,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row: IListCohortStudent) =>
        handleNullUndefined(row.user.email),
      sortable: true,
      grow: 3,
    },
    {
      name: "Phone",
      selector: (row: IListCohortStudent) =>
        handleNullUndefined(row.user.phoneNumber),
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      grow: 2,
      cell: (row: IListCohortStudent) => (
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={() => handleRemoveClick(row._id)}
            className="btn btn-danger"
          >
            Remove Student
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search students in cohort..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={handleRefreshClick}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredCohortStudents = cohortStudent.filter((students) => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const email = students.user.email ? students.user.email.toLowerCase() : "";
    const fullName =
      `${students.user.firstName} ${students.user.lastName}`.toLowerCase();

    return (
      email.includes(lowerCaseSearchQuery) ||
      fullName.includes(lowerCaseSearchQuery)
    );
  });

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div>
      <h4>List of students in cohort</h4>
      <div className="w-100 my-2 d-flex justify-content-end">
        <button
          onClick={handleOpenStudentModal}
          className="btn btn-secondary btn-block d-flex align-items-center justify-content-center"
        >
          Add a Student
        </button>
      </div>
      <DataTable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={filteredCohortStudents}
        columns={columns}
        pagination
        paginationPerPage={10}
        customStyles={customStyles}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />
      <AddStudent
        handleCloseStudentModal={handleCloseStudentModal}
        open={open}
        cohortId={cohortId}
        setCohortStudent={setCohortStudent}
      />
    </div>
  );
};

export default CohortStudents;
