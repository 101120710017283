import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import WordEditor from "components/WordEditor";
import { IAddProjects } from "types/addProject";
import { toast } from "react-toastify";
import { white } from "config/colors";
import { category } from "types/constant";
import { uploadFileToS3 } from "hooks/s3Utils";

const EditProject: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [project, setProjects] = useState<IAddProjects | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingProject, setEditingProject] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    get(`/projects/${slug}`)
      .then((response) => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!project) {
    return <div>No project found for the given SLUG.</div>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEditingProject(true);
    try {
      if (fileInputRef.current?.files?.[0]) {
        const selectedFile = fileInputRef.current.files[0];
        const uploadedUrl = await uploadFileToS3(selectedFile, "project");
        project.image = uploadedUrl;
      }
      await put(`/projects/${project._id}`, project);
      toast.success("Project updated successfully");
      setEditingProject(false);
    } catch (error: any) {
      toast.error(`Error updating project: ${error}`);
      setEditingProject(false);
    }
  };

  return (
    <form>
      <div className="container">
        <div className="row">
          <div className="">
            <div className="form-floating mb-3">
              <input
                type="text"
                name="title"
                onChange={(e) =>
                  setProjects({ ...project, title: e.target.value })
                }
                value={project.title}
                className="form-control"
                placeholder="Title"
              />
              <label>Title</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                name="description"
                onChange={(e) =>
                  setProjects({ ...project, description: e.target.value })
                }
                value={project.description}
                className="form-control"
                placeholder="Description"
              />
              <label>Description</label>
            </div>
          </div>

          <div className="">
            <div className="form-floating mb-3">
              <input
                type="file"
                name="image"
                accept=".png, .jpg, .jpeg"
                ref={fileInputRef}
                onChange={(e) =>
                  setProjects({ ...project, image: e.target.value })
                }
                className="form-control"
                placeholder="Image Url"
              />
              <label>Image Url</label>
            </div>
            <div className="w-100 col-6 align-items-center justify-content-center position-relative">
              <img
                className="rounded m-1 d-flex"
                style={{
                  objectFit: "contain",
                }}
                src={project.image}
              />
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-select"
                name="category"
                onChange={(e) =>
                  setProjects({ ...project, category: e.target.value })
                }
                value={project.category}
              >
                <option value="">Select Category</option>
                {category.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <label>Type</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="">
            <p>Codes</p>
            <div className="form-floating mb-3">
              <textarea
                value={project.code.html || ""}
                onChange={(e) =>
                  setProjects({
                    ...project,
                    code: { ...project.code, html: e.target.value },
                  })
                }
                className="form-control"
                placeholder="Enter HTML Code"
                style={{ height: 100 }}
              ></textarea>
              <label>HTML</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                value={project.code.css || ""}
                onChange={(e) =>
                  setProjects({
                    ...project,
                    code: { ...project.code, css: e.target.value },
                  })
                }
                className="form-control"
                placeholder="Enter CSS Code"
                style={{ height: 100 }}
              ></textarea>
              <label>CSS</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                value={project.code.javascript || ""}
                onChange={(e) =>
                  setProjects({
                    ...project,
                    code: { ...project.code, javascript: e.target.value },
                  })
                }
                className="form-control"
                placeholder="Enter JavaScript Code"
                style={{ height: 100 }}
              ></textarea>
              <label>JavaScript</label>
            </div>
          </div>
          <div className="">
            <p>Content</p>
            <WordEditor
              value={project.content}
              handleOnchange={(e) => setProjects({ ...project, content: e })}
              height={300}
            />
          </div>
        </div>
        <div className="pt-4 mt-4">
          <button
            className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
          >
            {editingProject ? (
              <CircularProgress sx={{ color: white }} size={24} thickness={8} />
            ) : (
              "Edit Project"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditProject;
