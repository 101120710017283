import React from "react";
import ListCohort from "./components/ListCohort";
import { useCohortList } from "hooks/cohorts";

const CohortList = () => {
  const { data, isLoading, isFetching, refetch } = useCohortList();

  const cohortList = data?.data;
  const loading = isLoading || isFetching;

  return (
    <div className="">
      <div className="w-100">
        <ListCohort
          cohortList={cohortList || []}
          refetch={refetch}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CohortList;
