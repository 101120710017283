import React, { useEffect, useState } from "react";
import { get, deleteRequest } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";

interface IPartner {
  _id: string;
  name: string;
  mobile: string;
  email: string;
  address: string;
}

const ListPartners: React.FC = () => {
  const history = useHistory();
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get("/partners")
      .then((response) => {
        setPartners(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching partner data:", error);
        setLoading(false);
      });
  }, []);

  const handleEditClick = (id: string) => {
    history.push(`/dashboard/edit-partner/${id}`);
  };

  const handleDeleteClick = (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this partner?"
    );
    if (confirmDelete) {
      deleteRequest(`/partners/${id}`)
        .then(() => {
          return get("/partners");
        })
        .then((response) => {
          setPartners(response.data);
        })
        .catch((error) => {
          toast.error("Error deleting partner:", error);
        });
    }
  };

  const handleRowClicked = (row: IPartner) => {
    history.push(`/dashboard/edit-partner/${row._id}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Name",
      selector: (row: IPartner) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: IPartner) => row.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row: IPartner) => row.mobile,
      sortable: true,
    },

    {
      name: "Address",
      selector: (row: IPartner) => row.address,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: IPartner) => (
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={() => handleEditClick(row._id)}
            className="btn btn-warning me-2"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={() => handleDeleteClick(row._id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search partner..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredPartner = partners.filter(
    (partner) =>
      partner.name.toLowerCase().includes(searchQuery) ||
      partner.mobile.toLowerCase().includes(searchQuery) ||
      partner.email.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="">
      <div className="w-100">
        <h3>Partners</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredPartner}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
            highlightOnHover
            pointerOnHover
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListPartners;
