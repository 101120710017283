import React from "react";
import { ThemeProvider } from "@mui/material";
import Theme from "config/theme";
import AppRoute from "./routes";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import queryClient from "query-client";
import InternetStatusToast from "components/InternetStatusToast";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <InternetStatusToast />
          <AppRoute />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
