import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { get, post } from "api";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IListCohortStudent } from "types/cohort";

interface AddStudentProps {
  handleCloseStudentModal: () => void;
  open: boolean;
  cohortId: string;
  setCohortStudent: React.Dispatch<React.SetStateAction<IListCohortStudent[]>>;
}

interface IStudents {
  _id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "80%",
  width: "40%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const AddStudent: React.FC<AddStudentProps> = ({
  handleCloseStudentModal,
  open,
  cohortId,
  setCohortStudent,
}) => {
  const [students, setStudents] = useState<IStudents[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    get("/admin/student")
      .then((response) => {
        setStudents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching students:", error);
      });
  }, []);

  const handleAddStudentToCohort = async (studentId: string) => {
    const requestBody = {
      cohort: cohortId,
      user: studentId,
    };

    await toast.promise(post("/cohorts/students", requestBody), {
      pending: "Adding student...",
      success: "Student added successfully",
      error: "Error adding student to cohort",
    });

    get(`/cohorts/${cohortId}/students`).then((response) => {
      setCohortStudent(response.data);
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Name",
      selector: (row: IStudents) => row.firstName + " " + row.lastName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row: IStudents) => row.email,
      sortable: true,
      grow: 3,
    },
    {
      name: "Phone",
      selector: (row: IStudents) => row.phoneNumber,
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      grow: 2,
      cell: (row: IStudents) => (
        <div className="btn-group">
          <button
            type="button"
            onClick={() => handleAddStudentToCohort(row._id)}
            className="btn btn-success"
          >
            Add Student
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
    searchInput: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search students..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredStudents = students.filter((student) => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const email = student.email ? student.email.toLowerCase() : "";
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();

    return (
      email.includes(lowerCaseSearchQuery) ||
      fullName.includes(lowerCaseSearchQuery)
    );
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseStudentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredStudents}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          /> */}
          <Box className="d-flex align-items-center justify-content-center fw-bold">
            <p>Comming Soon</p>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddStudent;
