import { white } from "config/colors";
import React, { useState } from "react";
import { ICohort } from "types/cohort";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { post } from "api";
import { usePartnersList } from "hooks/cohorts";
import { Box } from "@mui/material";

const initialCohortData: ICohort = {
  name: "",
  partnerId: "default",
  status: "inactive",
  includable: false,
  courses: [],
};

const AddCohort = () => {
  const [cohortData, setCohortData] = useState<ICohort>(initialCohortData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: partners, isLoading, isFetching } = usePartnersList();

  if (isLoading || isFetching)
    return (
      <Box textAlign="center">
        <CircularProgress size={30} />
        <p style={{ fontSize: "16px" }}>Wait while we get partners list</p>
      </Box>
    );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!cohortData.name.trim()) {
      toast.error("Cohort Name is required");
      return;
    }

    try {
      setIsSubmitting(true);
      let payload = { ...cohortData };

      if (cohortData.partnerId === "default") {
        const { partnerId, ...rest } = payload;
        payload = rest;
      }
      await post("/cohorts", payload);
      setCohortData(initialCohortData);
      setIsSubmitting(false);
      toast.success("Cohort created successfully");
    } catch (err) {
      setIsSubmitting(false);
      toast.error("Error creating cohorts");
    }
  };

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit}>
      <label>
        Cohort Name:
        <input
          className="form-control mb-2"
          type="text"
          required
          value={cohortData.name}
          onChange={(e) =>
            setCohortData({
              ...cohortData,
              name: e.target.value,
            })
          }
        />
      </label>
      <label className="mb-2">
        Select Partners:
        <select
          value={cohortData.partnerId}
          onChange={(e) =>
            setCohortData({
              ...cohortData,
              partnerId: e.target.value,
            })
          }
        >
          <option value="default">Default</option>
          {partners?.data.map((partner) => {
            return (
              <option value={partner._id} key={partner._id}>
                {partner.name}
              </option>
            );
          })}
        </select>
      </label>
      <label className="mb-2">
        Status:
        <select
          value={cohortData.status}
          onChange={(e) =>
            setCohortData({
              ...cohortData,
              status: e.target.value as "active" | "inactive",
            })
          }
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </label>

      <label>
        Includable:
        <input
          type="checkbox"
          checked={cohortData.includable}
          onChange={(e) =>
            setCohortData({
              ...cohortData,
              includable: e.target.checked,
            })
          }
        />
      </label>

      <button
        className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
        onClick={handleSubmit}
      >
        {isSubmitting ? (
          <CircularProgress sx={{ color: white }} size={24} thickness={8} />
        ) : (
          "Create Cohort"
        )}
      </button>
    </form>
  );
};

export default AddCohort;
