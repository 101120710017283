import { Box } from "@mui/material";

const Index = () => {
  return (
    <Box mt={12} textAlign="center">
      <h1>Welcome to Admin Page</h1>
    </Box>
  );
};

export default Index;
