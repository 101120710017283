import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { get, post } from "api";
import { toast } from "react-toastify";
import { ICohortLiveSessions, IListCohortLiveSessions } from "types/cohort";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";

interface AddLiveSessionsProps {
  handleCloseStudentModal: () => void;
  open: boolean;
  cohortId: string;
  setCohortLiveSession: React.Dispatch<
    React.SetStateAction<IListCohortLiveSessions[]>
  >;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const AddLiveSessions: React.FC<AddLiveSessionsProps> = ({
  cohortId,
  handleCloseStudentModal,
  open,
  setCohortLiveSession,
}) => {
  const initialLiveSessionData: ICohortLiveSessions = {
    title: "",
    description: "",
    url: "",
    time: "",
    expiry_time: "",
  };

  const [liveSession, setLiveSession] = useState<ICohortLiveSessions>(
    initialLiveSessionData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setLiveSession({ ...liveSession, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await post(`/cohorts/${cohortId}/live_session`, liveSession);
      get(`/cohorts/${cohortId}/live_session`).then((response) => {
        setCohortLiveSession(response.data);
      });
      handleCloseStudentModal();
      setLiveSession(initialLiveSessionData);
      setIsSubmitting(false);
      toast.success("Live session scheduled");
    } catch (err) {
      setIsSubmitting(false);
      toast.error("Error scheduling live session");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseStudentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <label>
              Title:
              <input
                className="form-control mb-2"
                type="text"
                name="title"
                required
                value={liveSession.title}
                onChange={handleChange}
              />
            </label>
            <label className="mb-2">
              Description:
              <textarea
                className="form-control mb-2"
                name="description"
                value={liveSession.description}
                onChange={handleChange}
              />
            </label>
            <label>
              URL:
              <input
                className="form-control mb-2"
                type="text"
                name="url"
                required
                value={liveSession.url}
                onChange={handleChange}
              />
            </label>

            <label htmlFor="time">
              Time:
              <input
                className="form-control"
                type="datetime-local"
                id="time"
                name="time"
                value={liveSession.time}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Expiry time:
              <input
                className="form-control mb-2"
                type="datetime-local"
                id="expiry_time"
                name="expiry_time"
                required
                value={liveSession.expiry_time}
                onChange={handleChange}
              />
            </label>

            <button
              className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
              onClick={handleSubmit}
            >
              {isSubmitting ? (
                <CircularProgress
                  sx={{ color: white }}
                  size={24}
                  thickness={8}
                />
              ) : (
                "Schedule Live Session"
              )}
            </button>
          </form>{" "}
        </Box>
      </Modal>
    </div>
  );
};

export default AddLiveSessions;
