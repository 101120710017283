import { gray } from "config/colors";
import React from "react";

const Divider = () => {
  return (
    <div
      className="w-100 my-5"
      style={{
        height: 2,
        background: gray,
        borderRadius: 10,
      }}
    />
  );
};

export default Divider;
