import React from "react";
import TextField from "components/Form/TextField";
import { TextFieldContainer, SubmitButton } from "../Common";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ILoginFormValues } from "types/auth";

const validationSchema = Yup.object().shape({
  phoneOrEmail: Yup.string().required("Email address is required"),
  password: Yup.string()
    .min(4, "Password should be more than 4 characters")
    .required("Password is required"),
});

export interface ISignInFormProps {
  onSubmit: (values: ILoginFormValues) => Promise<any> | any;
}

//Renders the sign in form.
const SignInForm = ({ onSubmit }: ISignInFormProps) => {
  const initialValues: ILoginFormValues = {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <TextFieldContainer>
            <TextField
              type="text"
              name="phoneOrEmail"
              error={!!(errors.phoneOrEmail && touched.phoneOrEmail)}
              helperText={errors.phoneOrEmail}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                handleChange({
                  target: { name: "phoneOrEmail", value: trimmedValue },
                });
              }}
              onBlur={handleBlur}
              value={values.phoneOrEmail || ""}
              label="Your Email Address or Phone"
              variant="outlined"
            />
          </TextFieldContainer>

          <TextFieldContainer>
            <TextField
              type="password"
              name="password"
              error={!!(errors.password && touched.password)}
              helperText={errors.password}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                handleChange({
                  target: { name: "password", value: trimmedValue },
                });
              }}
              onBlur={handleBlur}
              value={values.password || ""}
              label="Password"
              variant="outlined"
            />
          </TextFieldContainer>

          <Box flexDirection="row" textAlign="center">
            <SubmitButton
              type="submit"
              variant="contained"
              style={{ width: "100%" }}
            >
              {isSubmitting ? "Please wait..." : "Login to your Account"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
