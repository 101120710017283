import React, { useState } from "react";
import { IQuestion, IAddQuiz } from "types/addQuiz";
import { useMutation } from "react-query";
import { post } from "api";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { red, white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";

const initialQuestion: IQuestion = {
  id: "question_1",
  question: "",
  options: [
    { option: "", is_answer: false },
    { option: "", is_answer: false },
  ],
};

const QuizForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState<IQuestion[]>([initialQuestion]);

  const addQuestion = () => {
    setQuestions((prev) => [
      ...prev,
      { ...initialQuestion, id: `question_${prev.length + 1}` },
    ]);
  };

  const addOption = (questionId: string) => {
    setQuestions((prev) =>
      prev.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            options: [...question.options, { option: "", is_answer: false }],
          };
        }
        return question;
      })
    );
  };

  const deleteQuestion = (questionId: string) => {
    setQuestions((prev) =>
      prev.filter((question) => question.id !== questionId)
    );
  };

  const deleteOption = (questionId: string, optionIndex: number) => {
    setQuestions((prev) =>
      prev.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            options: question.options.filter(
              (_, index) => index !== optionIndex
            ),
          };
        }
        return question;
      })
    );
  };

  const handleCheckboxChange = (questionId: string, optionIndex: number) => {
    setQuestions((prev) =>
      prev.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            options: question.options.map((option, index) => ({
              ...option,
              is_answer: index === optionIndex,
            })),
          };
        }
        return question;
      })
    );
  };

  const mutation = useMutation((data: IAddQuiz) => post("/admin/quiz", data), {
    onSuccess: () => {
      toast("Quiz added", { type: "success" });
      setTitle("");
      setQuestions([initialQuestion]);
    },
    onError: (error: AxiosError) => {
      toast(error.message, { type: "error" });
    },
  });

  const handleSubmit = () => {
    const data: IAddQuiz = {
      title,
      items: questions,
    };
    mutation.mutate(data);
  };

  return (
    <div>
      <div className="mb-5">
        <input
          type="text"
          value={title}
          className="form-control"
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Quiz Title"
        />
      </div>
      {questions.map((question) => (
        <div className="" key={question.id}>
          <input
            type="text"
            className="form-control mt-4"
            value={question.question}
            onChange={(e) =>
              setQuestions((prev) =>
                prev.map((q) =>
                  q.id === question.id ? { ...q, question: e.target.value } : q
                )
              )
            }
            placeholder="Question"
          />
          {question.options.map((option, index) => (
            <div className="d-flex my-2 align-items-center" key={index}>
              <input
                type="checkbox"
                checked={option.is_answer}
                onChange={() => handleCheckboxChange(question.id, index)}
              />
              <input
                type="text"
                className="form-control ms-2"
                value={option.option}
                onChange={(e) =>
                  setQuestions((prev) =>
                    prev.map((q) =>
                      q.id === question.id
                        ? {
                            ...q,
                            options: q.options.map((o, i) =>
                              i === index ? { ...o, option: e.target.value } : o
                            ),
                          }
                        : q
                    )
                  )
                }
                placeholder="Option"
              />
              {index >= 2 && (
                <div
                  className="cursor-pointer ms-1"
                  style={{ color: red }}
                  onClick={() => deleteOption(question.id, index)}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          ))}
          <button
            className="btn btn-dark btn-block m-2"
            onClick={() => addOption(question.id)}
          >
            Add Option
          </button>
          <button
            className="btn btn-danger btn-block m-2"
            onClick={() => deleteQuestion(question.id)}
          >
            Delete Question
          </button>
        </div>
      ))}
      <div className="w-100 d-flex justify-content-end">
        <button
          className="btn btn-secondary btn-block m-2"
          onClick={addQuestion}
        >
          Add Question
        </button>
      </div>
      <div>
        <button
          className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {mutation.isLoading ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            "Submit Quiz"
          )}
        </button>
      </div>
    </div>
  );
};

export default QuizForm;
