import { post } from "api";
import React from "react";
import { IVoucherValues } from "types/voucher";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { white } from "config/colors";

const AddVoucher = () => {
  const initialValues = {
    code: "",
    discount: 0,
    limit: 0,
    expireAt: new Date().toISOString().slice(0, 16).replace("T", " "),
  };

  const [formData, setFormData] = React.useState<IVoucherValues>(initialValues);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const numericValue = name === "discount" ? parseFloat(value) : value;
    setFormData({ ...formData, [name]: numericValue });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await post("/voucher", formData);
      setFormData(initialValues);
      setIsSubmitting(false);
      toast.success("Voucher created successfully");
    } catch (err) {
      setIsSubmitting(false);
      toast.error("Error creating voucher");
    }
  };

  return (
    <div>
      <h2>Add Voucher</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="code">Code:</label>
          <input
            className="form-control"
            type="text"
            id="code"
            name="code"
            value={formData.code}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="discount">Discount:</label>
          <input
            className="form-control"
            type="number"
            id="discount"
            name="discount"
            value={formData.discount}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="limit">Limit:</label>
          <input
            className="form-control"
            type="number"
            id="limit"
            name="limit"
            value={formData.limit}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="expireAt">Expire At:</label>
          <input
            className="form-control"
            type="datetime-local"
            id="expireAt"
            name="expireAt"
            value={formData.expireAt}
            onChange={handleChange}
            required
          />
        </div>
        <button
          className="mt-4 btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {isSubmitting ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            "Create Voucher"
          )}
        </button>
      </form>
    </div>
  );
};

export default AddVoucher;
