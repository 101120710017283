import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { IAddPartner } from "types/partner";
import { toast } from "react-toastify";
import { white } from "config/colors";
import ListCohort from "../cohort/components/ListCohort";
import { usePartner, usePartnerCohort } from "hooks/cohorts";

const EditPartner: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [partner, setPartners] = useState<IAddPartner | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingPartner, setEditingPartner] = useState(false);

  const { data, isLoading, isFetching, refetch } = usePartnerCohort(id);
  const { data: partnerDetails } = usePartner(id);
  const defaultCohort = partnerDetails?.data.defaultCohort;

  const partnerCohortList = data?.data;
  const loadingPartnerCohort = isLoading || isFetching;

  useEffect(() => {
    get(`/partners/${id}`)
      .then((response) => {
        setPartners(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching partner data:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!partner) {
    return <div>No partner found for the given ID.</div>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEditingPartner(true);
    try {
      await put(`/partners/${partner._id}`, partner);
      toast.success("Partner updated successfully");
      setEditingPartner(false);
    } catch (error: any) {
      toast.error(`Error updating partner: ${error}`);
      setEditingPartner(false);
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className="container">
          <div className="row">
            <div className="">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="name"
                  onChange={(e) =>
                    setPartners({ ...partner, name: e.target.value })
                  }
                  value={partner.name}
                  className="form-control"
                  placeholder="Name"
                />
                <label>Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="address"
                  onChange={(e) =>
                    setPartners({ ...partner, address: e.target.value })
                  }
                  value={partner.address}
                  className="form-control"
                  placeholder="Address"
                />
                <label>Address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="number"
                  name="mobile"
                  onChange={(e) =>
                    setPartners({ ...partner, mobile: e.target.value })
                  }
                  value={partner.mobile}
                  className="form-control"
                  placeholder="Mobile"
                />
                <label>Mobile</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  name="email"
                  onChange={(e) =>
                    setPartners({ ...partner, email: e.target.value })
                  }
                  value={partner.email}
                  className="form-control"
                  placeholder="Email"
                />
                <label>Email</label>
              </div>
            </div>
          </div>

          <div className="pt-4 mt-4">
            <button
              className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
              onClick={handleSubmit}
            >
              {editingPartner ? (
                <CircularProgress
                  sx={{ color: white }}
                  size={24}
                  thickness={8}
                />
              ) : (
                "Edit Partner"
              )}
            </button>
          </div>
        </div>
      </form>

      <div className="w-100 mt-4">
        <ListCohort
          cohortList={partnerCohortList || []}
          refetch={refetch}
          loading={loadingPartnerCohort}
          showDefaultAction={true}
          defaultCohort={defaultCohort}
        />
      </div>
    </React.Fragment>
  );
};

export default EditPartner;
