import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { red, white } from "config/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

interface Quiz {
  _id: string;
  title: string;
  items: {
    id: string;
    question: string;
    options: {
      option: string;
      is_answer: boolean;
    }[];
  }[];
}

const EditQuiz: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingQuiz, setEditingQuiz] = useState(false);

  useEffect(() => {
    get(`/admin/quiz/${slug}`)
      .then((response) => {
        setQuiz(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching content data:", error);
        setLoading(false);
      });
  }, [slug]);

  const addQuestion = () => {
    setQuiz((prevQuiz) => ({
      ...prevQuiz!,
      items: [
        ...(prevQuiz?.items || []),
        {
          id: `question_${Date.now()}`,
          question: "",
          options: [
            { option: "", is_answer: false },
            { option: "", is_answer: false },
          ],
        },
      ],
    }));
  };

  const addOption = (questionId: string) => {
    setQuiz((prevQuiz) => ({
      ...prevQuiz!,
      items: (prevQuiz?.items || []).map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            options: [
              ...(question.options || []),
              { option: "", is_answer: false },
            ],
          };
        }
        return question;
      }),
    }));
  };

  const deleteQuestion = (questionId: string) => {
    setQuiz((prevQuiz) => ({
      ...prevQuiz!,
      items: (prevQuiz?.items || []).filter(
        (question) => question.id !== questionId
      ),
    }));
  };

  const deleteOption = (questionId: string, optionIndex: number) => {
    setQuiz((prevQuiz) => ({
      ...prevQuiz!,
      items: (prevQuiz?.items || []).map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            options: question.options.filter(
              (_, index) => index !== optionIndex
            ),
          };
        }
        return question;
      }),
    }));
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (!quiz) {
    return <div>No quiz found for the given SLUG.</div>;
  }

  const handleSubmit = async () => {
    setEditingQuiz(true);
    try {
      await put(`/admin/quiz/${quiz._id}`, quiz);
      toast.success("Quiz updated successfully");
      setEditingQuiz(false);
    } catch (error: any) {
      toast.error(`Error updating quiz: ${error}`);
      setEditingQuiz(false);
    }
  };

  return (
    <div>
      <div className="mb-5">
        <input
          type="text"
          value={quiz.title}
          className="form-control"
          onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
          placeholder="Quiz Title"
        />
      </div>
      {quiz.items.map((question, questionIndex) => (
        <div className="" key={questionIndex}>
          <div className="d-flex align-items-center mt-4">
            <p className="mb-0 me-1">{`(${questionIndex + 1}).`}</p>
            <input
              type="text"
              className="form-control"
              value={question.question}
              onChange={(e) =>
                setQuiz((prevQuiz) => {
                  if (!prevQuiz) return prevQuiz;

                  const updatedItems = prevQuiz.items.map((q, qIndex) =>
                    qIndex === questionIndex
                      ? { ...q, question: e.target.value }
                      : q
                  );

                  return { ...prevQuiz, items: updatedItems };
                })
              }
              placeholder="Question"
            />
          </div>

          {question.options.map((option, optionIndex) => (
            <div className="d-flex my-2 align-items-center" key={optionIndex}>
              <input
                type="checkbox"
                checked={option.is_answer}
                onChange={() => {
                  setQuiz((prevQuiz) => {
                    if (!prevQuiz) return prevQuiz;

                    const updatedItems = prevQuiz.items.map((q, qIndex) =>
                      qIndex === questionIndex
                        ? {
                            ...q,
                            options: q.options.map((o, oIndex) =>
                              oIndex === optionIndex
                                ? { ...o, is_answer: true }
                                : { ...o, is_answer: false }
                            ),
                          }
                        : q
                    );

                    return { ...prevQuiz, items: updatedItems };
                  });
                }}
              />
              <input
                type="text"
                className="form-control ms-2"
                value={option.option}
                onChange={(e) =>
                  setQuiz((prevQuiz) => {
                    if (!prevQuiz) return prevQuiz;

                    const updatedItems = prevQuiz.items.map((q, qIndex) =>
                      qIndex === questionIndex
                        ? {
                            ...q,
                            options: q.options.map((o, oIndex) =>
                              oIndex === optionIndex
                                ? { ...o, option: e.target.value }
                                : o
                            ),
                          }
                        : q
                    );

                    return { ...prevQuiz, items: updatedItems };
                  })
                }
                placeholder={`Option ${optionIndex + 1}`}
              />
              {optionIndex >= 2 && (
                <div
                  className="cursor-pointer ms-1"
                  style={{ color: red }}
                  onClick={() => deleteOption(question.id, optionIndex)}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          ))}
          <button
            className="btn btn-dark btn-block m-2"
            onClick={() => addOption(question.id)}
          >
            Add Option
          </button>
          <button
            className="btn btn-danger btn-block m-2"
            onClick={() => deleteQuestion(question.id)}
          >
            Delete Question
          </button>
        </div>
      ))}
      <div className="w-100 d-flex justify-content-end">
        <button
          className="btn btn-secondary btn-block m-2"
          onClick={addQuestion}
        >
          Add Question
        </button>
      </div>
      <div>
        <button
          className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {editingQuiz ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            "Edit Quiz"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditQuiz;
