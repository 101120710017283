import { createTheme } from "@mui/material";
import { primary, white } from "./colors";

const Theme = createTheme({
    palette:{
        primary:{
            main:primary,
            contrastText: white
        },
    },
})

export default Theme;