import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { get, post } from "api";
import { IAddLessons, Lessons } from "types/addLesson";
import { lessonType } from "types/constant";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const initialFormData: IAddLessons = {
  title: "",
  description: "",
  type_reference_id: "",
  type: "",
};

const AddLesson: React.FC = () => {
  const [formData, setFormData] = useState<IAddLessons>(initialFormData);
  const [quizData, setQuizData] = useState<Lessons[]>([]);
  const [contentsData, setContentsData] = useState<Lessons[]>([]);
  const [projectsData, setProjectsData] = useState<Lessons[]>([]);

  const mutation = useMutation((data: IAddLessons) => post("/lessons", data), {
    onSuccess: () => {
      toast("Lesson added", { type: "success" });
      setFormData(initialFormData);
    },
    onError: (error: AxiosError) => {
      toast(error.message, { type: "error" });
    },
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "type") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        type_reference_id: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  useEffect(() => {
    get("/admin/quiz")
      .then((response) => {
        setQuizData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching quiz data:", error);
      });
  }, []);

  useEffect(() => {
    get("/contents")
      .then((response) => {
        setContentsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching content data:", error);
      });
  }, []);

  useEffect(() => {
    get("/projects")
      .then((response) => {
        setProjectsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, []);

  const getTypeReferenceOptions = () => {
    let referenceData: { slug: string; _id: string; title: string }[] = [];
    switch (formData.type) {
      case "quiz":
        referenceData = quizData;
        break;
      case "content":
        referenceData = contentsData;
        break;
      case "project":
        referenceData = projectsData;
        break;
      default:
        return null;
    }
    const filteredOptions = referenceData.filter((data) =>
      data.title
        .toLowerCase()
        .includes(formData.type_reference_id.toLowerCase())
    );

    return (
      <>
        {filteredOptions.map((data) => (
          <option key={data._id} value={data._id}>
            {data.title}
          </option>
        ))}
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          className="form-control"
          value={formData.description}
          onChange={handleChange}
        />
      </div>
      <div className="form-floating mb-3">
        <select
          className="form-select"
          name="type"
          onChange={handleChange}
          value={formData.type}
        >
          <option value="">Select Type</option>
          {lessonType.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        <label>Type</label>
      </div>
      <div className="form-floating mb-3">
        <input
          list="type_reference_id_list"
          className="form-control"
          name="type_reference_id"
          onChange={handleChange}
          value={formData.type_reference_id}
          autoComplete="off"
        />
        <label>
          Select{" "}
          {formData.type === "quiz"
            ? "Quiz"
            : formData.type === "content"
            ? "Content"
            : "Project"}
        </label>
        <datalist id="type_reference_id_list">
          {getTypeReferenceOptions()}
        </datalist>
      </div>
      <button
        className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center mt-2"
        onClick={handleSubmit}
      >
        {mutation.isLoading ? (
          <CircularProgress sx={{ color: white }} size={24} thickness={8} />
        ) : (
          "Add Lesson"
        )}
      </button>
    </form>
  );
};

export default AddLesson;
