import { useEffect } from "react";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import { RouteComponentProps } from "react-router-dom";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

// import LocalizationProvider from '@mui/lab/LocalizationProvider'
import NotFound from "components/NotFound";
import PageLayout from "components/PageLayout";
import { IAccountRole } from "../types/auth";
import AddContent from "views/content/AddContent";
import AddQuiz from "views/Quiz";
import AddProject from "views/Project";
import AddLesson from "views/Lesson";
import ListContent from "views/content/ListContent";
import EditContent from "views/content/EditContent";
import ListProject from "views/Project/ListProject";
import EditProject from "views/Project/EditProject";
import EditQuiz from "views/Quiz/EditQuiz";
import ListQuiz from "views/Quiz/ListQuiz";
import ListCourse from "views/course/ListCourse";
import ManageCourse from "views/course/ManageCourse";
import ListUser from "views/listUser";
import ListLesson from "views/Lesson/ListLesson";
import EditLesson from "views/Lesson/EditLesson";
import AddVoucher from "views/voucher/AddVoucher";
import ListVoucher from "views/voucher/ListVoucher";
import EditVoucher from "views/voucher/EditVoucher";
import AddCohort from "views/cohort/AddCohort";
import EditCohort from "views/cohort/EditCohort";
import AddPartner from "views/Partners/AddPartner";
import ListPartners from "views/Partners/ListPartners";
import EditPartner from "views/Partners/EditPartner";
import CohortList from "views/cohort/CohortList";
import Instructors from "views/Instructors/ListInstructors";

/** Renders the dashboard routes */
const DashboardRoute = (props: RouteComponentProps) => {
  const { isLoading, isFetching, error, profile } = {
    isLoading: false,
    isFetching: false,
    error: false,
    profile: {
      name: "Admin",
      role: IAccountRole.ADMIN,
      user_id: 10001,
      email: "test@gmail.com",
    },
  };

  const history = useHistory();

  useEffect(() => {
    const unRegister = history.listen(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    });
    return unRegister;
  }, [history]);

  return (
    <PageLayout
      loading={isFetching || isLoading}
      error={error}
      onRetry={() => console.log("retry")}
    >
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <DashboardLayout account={profile!}>
        <Switch>
          <Route
            path="/dashboard/"
            exact
            render={() => <Redirect to="/dashboard/home" />}
          />
          <Route path="/dashboard/add-content" exact component={AddContent} />
          <Route path="/dashboard/add-quiz" component={AddQuiz} />
          <Route path="/dashboard/add-project" component={AddProject} />
          <Route path="/dashboard/add-lesson" component={AddLesson} />
          <Route path="/dashboard/add-course" component={ManageCourse} />
          <Route path="/dashboard/list-content" component={ListContent} />
          <Route path="/dashboard/list-project" component={ListProject} />
          <Route path="/dashboard/list-lesson" component={ListLesson} />
          <Route path="/dashboard/list-quiz" component={ListQuiz} />
          <Route path="/dashboard/list-course" component={ListCourse} />
          <Route path="/dashboard/list-user" component={ListUser} />
          <Route path="/dashboard/edit-content/:slug" component={EditContent} />
          <Route path="/dashboard/edit-project/:slug" component={EditProject} />
          <Route path="/dashboard/edit-quiz/:slug" component={EditQuiz} />
          <Route path="/dashboard/edit-lesson/:slug" component={EditLesson} />
          <Route path="/dashboard/edit-course/:slug" component={ManageCourse} />
          <Route path="/dashboard/add-voucher" component={AddVoucher} />
          <Route path="/dashboard/list-voucher" component={ListVoucher} />
          <Route path="/dashboard/edit-voucher/:code" component={EditVoucher} />
          <Route path="/dashboard/add-cohort/" component={AddCohort} />
          <Route path="/dashboard/list-cohort/" component={CohortList} />
          <Route path="/dashboard/edit-cohort/:id" component={EditCohort} />
          <Route path="/dashboard/add-partner/" exact component={AddPartner} />
          <Route path="/dashboard/partners/" exact component={ListPartners} />
          <Route path="/dashboard/edit-partner/:id" component={EditPartner} />
          <Route path="/dashboard/instructors/" exact component={Instructors} />

          <Route path="*" render={() => <NotFound homeUrl="/dashboard" />} />
        </Switch>
      </DashboardLayout>
      {/* </LocalizationProvider> */}
    </PageLayout>
  );
};

export default DashboardRoute;
