import {
  SortableItemProps,
  SortableList,
} from "@thaddeusjiang/react-sortable-list";
import { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface IProps {
  items: SortableItemProps[];
  onItemsUpdated: (items: SortableItemProps[]) => void;
  openItemOption: null | HTMLElement;
}

const SortableItem = ({ items, onItemsUpdated }: IProps) => {
  const [sortableItems, setSortableItems] =
    useState<SortableItemProps[]>(items);

  useEffect(() => {
    setSortableItems(items);
  }, [items]);

  useEffect(() => {
    if (sortableItems) {
      onItemsUpdated(sortableItems);
    }
  }, [sortableItems]);

  return (
    <SortableList
      items={items || []}
      setItems={setSortableItems}
      itemRender={({ item }) => (
        <div
          key={item.id}
          style={{
            boxShadow: "0 0 1px rgba(0, 0, 0, 0.3)",
          }}
          className="d-flex align-items-center justify-content-between bg-white w-100 mt-1 py-3 px-2 border rounded"
        >
          <div>{item.name}</div>
          <IconButton className="p-2">
            <MoreVertIcon />
          </IconButton>
        </div>
      )}
    />
  );
};

export default SortableItem;
