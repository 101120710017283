import React, { useEffect, useState } from "react";
import { get, deleteRequest } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";

interface Quiz {
  _id: string;
  slug: string;
  title: string;
}

const ListQuiz: React.FC = () => {
  const history = useHistory();
  const [quiz, setQuiz] = useState<Quiz[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get("/admin/quiz")
      .then((response) => {
        setQuiz(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching quiz data:", error);
        setLoading(false);
      });
  }, []);

  const handleEditClick = (slug: string) => {
    history.push(`/dashboard/edit-quiz/${slug}`);
  };

  const handleDeleteClick = (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this quiz?"
    );
    if (confirmDelete) {
      deleteRequest(`/admin/quiz/${id}`)
        .then(() => {
          return get("/admin/quiz");
        })
        .then((response) => {
          setQuiz(response.data);
        })
        .catch((error) => {
          console.error("Error deleting quiz:", error);
        });
    }
  };

  const handleRowClicked = (row: Quiz) => {
    history.push(`/dashboard/edit-quiz/${row.slug}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Title",
      selector: (row: Quiz) => row.title,
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      cell: (row: Quiz) => (
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={() => handleEditClick(row.slug)}
            className="btn btn-warning me-2"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={() => handleDeleteClick(row._id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search quiz..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredQuiz = quiz.filter((quizFilteredData) =>
    quizFilteredData.title.toLowerCase().includes(searchQuery)
  );

  return (
    <div>
      <div className="w-100">
        <h3>Quiz List</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredQuiz}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
            highlightOnHover
            pointerOnHover
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListQuiz;
