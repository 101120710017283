import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { SortableItemProps } from "@thaddeusjiang/react-sortable-list";
import { IconButton } from "@material-ui/core";
import { get, post } from "api";
import { toast } from "react-toastify";

interface CourseModalProps {
  handleCloseModal: () => void;
  cohortCourseModal: boolean;
  cohortId: string;
}

interface ICourse {
  _id: string;
  slug: string;
  title: string;
  description: string;
  status: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const CourseModal: React.FC<CourseModalProps> = ({
  handleCloseModal,
  cohortCourseModal,
  cohortId,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [courses, setCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    get("/admin/courses")
      .then((response) => {
        if (response && Array.isArray(response.data)) {
          setCourses(response.data);
        } else {
          console.error("Invalid response data:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const handleAddToCourse = (courseSlug: string) => {
    const userConfirm = window.confirm(
      "Are you sure you want to add this course to the cohort?"
    );

    if (userConfirm) {
      const requestBody = {
        cohort: cohortId,
        slug: courseSlug,
      };

      toast.promise(post(`/course/${courseSlug}/enroll/cohort`, requestBody), {
        pending: "Adding course to cohort...",
        success: "Added successfully",
        error: "Error adding course to cohort",
      });
    }
  };

  const columns = [
    {
      name: "Title",
      selector: (row: ICourse) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: ICourse) => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: ICourse) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: ICourse) => (
        <div className="btn-group">
          <button
            type="button"
            onClick={() => handleAddToCourse(row.slug)}
            className="btn btn-success"
          >
            Add Course
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
    searchInput: {
      style: {
        position: "fixed",
        width: "100%",
        top: 0,
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search courses..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredCourses = courses.filter(
    (course) =>
      course.title.toLowerCase().includes(searchQuery) ||
      course.description.toLowerCase().includes(searchQuery) ||
      course.status.toLowerCase().includes(searchQuery)
  );

  return (
    <div>
      <Modal open={cohortCourseModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredCourses}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default CourseModal;
