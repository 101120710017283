import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IWordEditorProps {
  value: string | undefined;
  handleOnchange: (values: string) => void;
  height?: number;
}

const WordEditor = (props: IWordEditorProps) => {
  const { value, handleOnchange, height } = props;
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={handleOnchange}
      style={{ height }}
    />
  );
};

export default WordEditor;
