import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

interface SubscribeUserProps {
  userId: string;
  openSubscribeUser: boolean;
  handleCloseSubscribeUserModal: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SubscribeUser: React.FC<SubscribeUserProps> = ({
  openSubscribeUser,
  handleCloseSubscribeUserModal,
}) => {
  return (
    <div>
      <Modal open={openSubscribeUser} onClose={handleCloseSubscribeUserModal}>
        <div>
          <Box sx={style}>
            <div className="mb-2">
              <label htmlFor="amount0paid">Amount paid:</label>
              <input
                type="number"
                placeholder="Amount paid"
                className="form-control"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="expiry_date">Expiry date:</label>
              <input
                className="form-control"
                type="datetime-local"
                id="expiry_date"
                name="expiry_date"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subscription-plan">Subscription plan:</label>
              <select
                id="subscription-plan"
                name="subscription-plan"
                className="form-control"
              >
                <option value="Basic">Basic</option>
                <option value="Standard">Standard</option>
              </select>
            </div>
            <button className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center">
              Subscribe
            </button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default SubscribeUser;
