import React from "react";
import { Box } from "@mui/material";
import SignInForm from "./SigninForm";
import {
  FormContainer,
  FormContent,
  Title,
  Layout,
  Banner,
} from "../Common";
import { useLoginMutation } from "../../../hooks/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import { IAuthData } from "types/auth";
import { setToken } from "api/cookie";
import Lottie from "react-lottie";
import AnimatedAuthImage from "../../../assets/animated/auth.json";
/** Renders the get started page */

const SignIn = () => {
  const history = useHistory();

  const { mutateAsync: submitLoginRequest } = useLoginMutation({
    onError: (error: AxiosError) => handleAuthError(error),
    onSuccess: (data: GenericAPIResponse<IAuthData>) => handleAuthSuccess(data),
  });

  const handleAuthError = (error: AxiosError) => {
    const data = error?.response?.data || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleAuthSuccess = (data: GenericAPIResponse<IAuthData>) => {
    //set cookie, then proceed to dashboard.
    setToken(data.data.token);
    history.replace("/dashboard");
    toast("Logged in success", { type: "success" });
  };

  return (
    <Layout>
      <FormContainer>
        <FormContent style={{ marginTop: 32 }}>
          <Title>Login to Your Account</Title>
          <span>Enter your details below to access your account</span>
          <Box mt={2}>
            <SignInForm onSubmit={submitLoginRequest} />
          </Box>
        </FormContent>
      </FormContainer>
      <Banner>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: AnimatedAuthImage,
          }}
        />
      </Banner>
    </Layout>
  );
};

export default SignIn;
