import React, { useState, useEffect } from "react";
import { post, get, put } from "api";
import {
  ICourseFormValues,
  IModule,
  ICourseData,
  IModuleFormValues,
} from "types/course";
import { silver, white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "@thaddeusjiang/react-sortable-list/dist/index.css";
import { IListLesson } from "types/addLesson";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import AddLessonModal from "./modal/AddLessonModal";
import SortableItem from "./SortableItems";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";

const initialFormData: ICourseFormValues = {
  _id: "",
  title: "",
  description: "",
  shortDescription: "",
  what_will_you_learn: [""],
  modules: [{ title: "", description: "", lessons: [], id: 1 }],
  projects: [""],
  status: "published",
  meta: { level: "beginner", includesCertificate: false, launchingSoon: true },
};

const metadataKeys = {
  includesCertificate: { type: "switch" },
  launchingSoon: { type: "switch" },
  level: { type: "select", options: ["beginner", "advance", "expert"] },
};

const ManageCourse: React.FC = () => {
  const history = useHistory();

  const { slug } = useParams<{ slug: string }>();
  const [formData, setFormData] = useState<ICourseFormValues>(initialFormData);
  const [projectsData, setProjectsData] = useState<IListLesson[]>([]);

  const [lessonsData, setLessonsData] = useState<IListLesson[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);

  const [editingModule, setEditingModule] = useState<IModuleFormValues | null>(
    null
  );
  const [course, setCourse] = useState<ICourseData | null>(null);

  const [openItemOption, setOpenItemOption] = useState<null | HTMLElement>(
    null
  );

  const isEditing = !!slug;

  const handleCloseLessonModal = () => {
    setEditingModule(null);
  };

  useEffect(() => {
    if (slug && isEditing) {
      setLoading(true);
      get(`/admin/course/${slug}`)
        .then((response) => {
          setCourse(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [slug, isEditing]);

  useEffect(() => {
    if (isEditing && !!course && lessonsData) {
      invalidateFormData(course);
    }
  }, [isEditing, course, lessonsData]);

  useEffect(() => {
    get("/lessons")
      .then((response) => {
        if (response.data) {
          const extractedLessons: IListLesson[] = response.data;
          setLessonsData(extractedLessons);
        } else {
          console.error("Invalid API response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching lessons:", error);
      });
  }, []);

  useEffect(() => {
    get("/projects")
      .then((response) => {
        setProjectsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, []);

  const invalidateFormData = (course: ICourseData) => {
    const formData: ICourseFormValues = {
      _id: course._id,
      title: course.title,
      description: course.description,
      shortDescription: course.shortDescription,
      what_will_you_learn: course.what_will_you_learn,
      modules: course.modules.map((module, index) => {
        return {
          id: index + 1,
          ...module,
          lessons: module.lessons.map((id) => {
            return {
              id,
              name: lessonsData.find((l) => l._id === id)?.title || "",
            };
          }),
        };
      }),
      projects: course.projects,
      status: course.status,
      meta: course.meta || {},
    };
    setFormData(formData);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === "select-multiple") {
      const selectElement = e.target as HTMLSelectElement;
      const selectedOptions = Array.from(selectElement.options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedOptions,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveLearn = (index: number) => {
    const newLearnArray = [...formData.what_will_you_learn];
    newLearnArray.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      what_will_you_learn: newLearnArray,
    }));
  };

  const handleRemoveModule = (index: number) => {
    const newModulesArray = [...formData.modules];
    newModulesArray.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      modules: newModulesArray,
    }));
  };

  const handleModuleInputChange = (
    index: number,
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    const updatedModules = [...formData.modules];
    updatedModules[index] = {
      ...updatedModules[index],
      [name]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      modules: updatedModules,
    }));
  };

  const handleAddLearn = () => {
    setFormData((prevData) => ({
      ...prevData,
      what_will_you_learn: [...prevData.what_will_you_learn, ""],
    }));
  };

  const handleAddModule = () => {
    setFormData((prevData) => ({
      ...prevData,
      modules: [
        ...prevData.modules,
        {
          title: "",
          description: "",
          lessons: [],
          id: prevData.modules.length + 1,
        },
      ],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    const newModules: IModule[] = formData.modules.map((module) => ({
      title: module.title,
      description: module.description,
      lessons: module.lessons.map((lesson) => lesson.id),
    }));

    const projects = formData.projects.filter((project) => project !== "");

    const newCourse: ICourseData = {
      _id: formData._id,
      title: formData.title,
      description: formData.description,
      shortDescription: formData.shortDescription,
      what_will_you_learn: formData.what_will_you_learn,
      modules: newModules,
      projects: projects,
      status: formData.status,
      meta: formData.meta,
    };

    try {
      if (isEditing) {
        await put(`/course/${course?._id}`, newCourse);
        toast.success("Course Updated successfully");
      } else {
        await post("/course", newCourse);
        toast.success("Course Added successfully");
      }
      setSubmitting(false);
      history.push("/dashboard/list-course");
    } catch (error: any) {
      toast.error("Error editing course, please check your form again:", error);
      setSubmitting(false);
    }
  };

  const updateModuleLesson = (module, lessons) => {
    setFormData((prev) => {
      const updatedModules = prev.modules.map((m) => {
        if (m.id === module.id) {
          return {
            ...m,
            lessons,
          };
        }
        return m;
      });
      return {
        ...prev,
        modules: updatedModules,
      };
    });
    //update the module
    setEditingModule((module) => {
      if (!module) return null;
      return {
        ...module,
        lessons,
      };
    });
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  const renderMetadataInput = (key: string) => {
    const metadataType = metadataKeys[key].type;
    const value = formData.meta[key];

    if (metadataType === "switch") {
      return (
        <div key={key} className="mt-2">
          <div className="d-flex align-items-center">
            <Switch
              checked={value}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  meta: {
                    ...prevData.meta,
                    [key]: e.target.checked,
                  },
                }))
              }
            />
            <span>{value ? "On" : "Off"}</span>
          </div>
        </div>
      );
    } else if (metadataType === "select") {
      const options = metadataKeys[key].options;

      return (
        <div key={key} className="mt-2">
          <select
            className="form-select"
            value={value}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                meta: {
                  ...prevData.meta,
                  [key]: e.target.value,
                },
              }))
            }
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  const renderMetadataTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Metadata</th>
            <th>Values</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(metadataKeys).map((key) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{renderMetadataInput(key)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h2>{isEditing ? "Edit Course" : "Add Course"}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label>Description</label>
          <input
            className="form-control"
            type="text"
            name="description"
            autoComplete="off"
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label>Short Description</label>
          <input
            className="form-control"
            type="text"
            name="shortDescription"
            autoComplete="off"
            value={formData.shortDescription}
            onChange={handleInputChange}
          />
        </div>

        {/* What Will You Learn */}

        <label>What Will You Learn</label>
        {formData.what_will_you_learn.map((learn, index) => (
          <div className="d-flex mb-1" key={index}>
            <input
              className="form-control me-2"
              autoComplete="off"
              type="text"
              name="what_will_you_learn"
              value={learn}
              onChange={(e) => {
                const newLearnArray = [...formData.what_will_you_learn];
                newLearnArray[index] = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  what_will_you_learn: newLearnArray,
                }));
              }}
            />
            <button
              className="btn btn-danger btn-block ms-2"
              type="button"
              onClick={() => handleRemoveLearn(index)}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
        <button
          className="btn btn-dark btn-block mt-2"
          type="button"
          onClick={handleAddLearn}
        >
          Add What to Learn
        </button>

        {/* Modules */}

        {formData.modules.map((module, index) => {
          return (
            <div className="mt-4" key={index}>
              <label>Module Title</label>
              <input
                className="form-control"
                type="text"
                name="title"
                value={module.title}
                onChange={(e) => handleModuleInputChange(index, e)}
              />
              <label>Module Description</label>
              <textarea
                className="form-control"
                name="description"
                value={module.description}
                onChange={(e) => handleModuleInputChange(index, e)}
              />

              {/* Module Lesson */}

              <div className="pb-4 pt-2 px-2 my-2 border">
                <div className="d-flex w-100 justify-content-between">
                  <label>Module Lesson</label>
                  <IconButton
                    className="p-2"
                    style={{
                      background: silver,
                      color: white,
                    }}
                    onClick={() => setEditingModule(module)}
                  >
                    <AddIcon />
                  </IconButton>
                </div>

                <SortableItem
                  items={module.lessons || []}
                  onItemsUpdated={(sortedItems) => {
                    updateModuleLesson(module, sortedItems);
                  }}
                  openItemOption={openItemOption}
                />
              </div>

              <button
                className="btn btn-danger btn-block mt-1"
                type="button"
                onClick={() => handleRemoveModule(index)}
              >
                <DeleteIcon />
              </button>
            </div>
          );
        })}

        <AddLessonModal
          lessonsData={lessonsData}
          handleCloseLessonModal={handleCloseLessonModal}
          openLessonModal={!!editingModule}
          setSelectedLessons={(lesson) => {
            updateModuleLesson(editingModule, lesson);
          }}
          selectedLessons={editingModule?.lessons || []}
        />

        <button
          className="btn btn-dark btn-block mt-2"
          type="button"
          onClick={handleAddModule}
        >
          Add Module
        </button>

        {/* Projects */}

        <div className="mt-4">
          <label>Projects</label>
          <select
            className="form-select"
            name="projects"
            multiple
            value={formData.projects}
            onChange={handleInputChange}
          >
            <option value="">Select a Project</option>
            {projectsData.map((data) => (
              <option key={data._id} value={data._id}>
                {data.title}
              </option>
            ))}
          </select>
        </div>

        {/* Course Status */}

        <div className="mt-2 mb-4">
          <label>Status</label>
          <select
            className="form-select"
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </select>
        </div>

        {renderMetadataTable()}

        <button
          className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {isSubmitting ? (
            <CircularProgress sx={{ color: white }} size={24} thickness={8} />
          ) : (
            <div>{isEditing ? "Edit Course" : "Add Course"}</div>
          )}
        </button>
      </form>
    </div>
  );
};

export default ManageCourse;
