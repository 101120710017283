import React from "react";
import { toast } from "react-toastify";
import { GenericAPIResponse } from "types/generic";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IContentData, IContentFormValues } from "../../types/content";
import { useContentMutation } from "hooks/content";
import { category, type } from "../../types/constant";
import CircularProgress from "@mui/material/CircularProgress";
import { white } from "config/colors";
import { uploadFileToS3 } from "hooks/s3Utils";
import MDEditor from "@uiw/react-md-editor";

const AddContent = () => {
  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    contentUrl: Yup.string(),
  });

  const initialValues: IContentFormValues = {
    title: "",
    description: "",
    content: "",
    category: category[0],
    type: type[0],
    contentUrl: "",
    code: { html: "", css: "", javascript: "" },
  };

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (!values.contentUrl) {
          delete values.contentUrl;
        }

        // If the type is "image" or "video" and a file is selected, upload it to S3
        if (
          (values.type === "image" || values.type === "video") &&
          fileInputRef.current?.files?.[0]
        ) {
          const selectedFile = fileInputRef.current.files[0];
          const uploadedUrl = await uploadFileToS3(selectedFile, "content");
          values.contentUrl = uploadedUrl;
        }

        await onSubmit(values);

        resetForm();
      } catch (error) {
        handleSaveError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { mutateAsync: onSubmit } = useContentMutation({
    onSuccess: (data: GenericAPIResponse<IContentData>) =>
      handleSaveSuccess(data),
  });

  const handleSaveError = (error: any) => {
    const message = `${error}`;
    toast(message, { type: "error" });
    console.log(error);
  };

  const handleSaveSuccess = (data: GenericAPIResponse<IContentData>) => {
    toast("Content added successfully", { type: "success" });
  };

  const handleMDEditorChange = (value) => {
    setFieldValue("content", value);
  };

  const handleCodeOnChange = (value, property) => {
    setFieldValue(`code.${property}`, value);
  };

  React.useEffect(() => {
    setFieldValue("code.html", "");
    setFieldValue("code.css", "");
    setFieldValue("code.javascript", "");
    setFieldValue("contentUrl", "");
  }, [values.type]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        {/* This is the first row that contain title, description, category, and type */}
        <div className="row">
          {/* This is the left coumn where title and description inputs are */}
          <div className="col-6">
            <div className="form-floating mb-3">
              <input
                type="text"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className="form-control"
                placeholder="Title"
              />
              <label>Title</label>
              {!!(errors.title && touched.title) && (
                <div className="invalid-feedback">{errors.title}</div>
              )}
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                className="form-control"
                placeholder="Description"
              />
              <label>Description</label>
              {!!(errors.description && touched.description) && (
                <div className="invalid-feedback">{errors.description}</div>
              )}
            </div>
          </div>

          {/* This is the right column where category and type inputs are */}
          <div className="col-6">
            <div className="form-floating mb-3">
              <select
                className="form-select"
                name="type"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
              >
                <option value="">Select Type</option>
                {type.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <label>Type</label>
              {!!(errors.type && touched.type) && (
                <div className="invalid-feedback">{errors.type}</div>
              )}
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-select"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.category}
              >
                <option value="">Select Category</option>
                {category.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <label>Category</label>
              {!!(errors.category && touched.category) && (
                <div className="invalid-feedback">{errors.category}</div>
              )}
            </div>
          </div>
        </div>

        {/* Second row */}
        <div className="row">
          {/* This is a left column where the code inputs are */}
          {values.type === "code" && (
            <div className="col-6">
              <p>Codes</p>
              <div className="form-floating mb-3">
                <textarea
                  defaultValue={initialValues.code.html}
                  value={values.code.html}
                  onChange={(e) => handleCodeOnChange(e.target.value, "html")}
                  className="form-control"
                  placeholder="Enter HTML Code"
                  style={{ height: 100 }}
                ></textarea>
                <label>HTML</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  defaultValue={initialValues.code.css}
                  value={values.code.css}
                  onChange={(e) => handleCodeOnChange(e.target.value, "css")}
                  className="form-control"
                  placeholder="Enter CSS Code"
                  style={{ height: 100 }}
                ></textarea>
                <label>CSS</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  defaultValue={initialValues.code.javascript}
                  value={values.code.javascript}
                  onChange={(e) =>
                    handleCodeOnChange(e.target.value, "javascript")
                  }
                  className="form-control"
                  placeholder="Enter JavaScript Code"
                  style={{ height: 100 }}
                ></textarea>
                <label>JavaScript</label>
              </div>
            </div>
          )}

          {/* This is the right column where code editor or file input is */}
          <div className="col-6">
            {values.type === "image" || values.type === "video" ? (
              <div className="form-floating mb-3">
                <input
                  type="file"
                  name="contentUrl"
                  ref={fileInputRef}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder={`Upload ${
                    values.type === "image" ? "Image" : "Video"
                  }`}
                />
                <label>{`Upload ${
                  values.type === "image" ? "Image" : "Video"
                }`}</label>
                {!!(errors.contentUrl && touched.contentUrl) && (
                  <div className="invalid-feedback">{errors.contentUrl}</div>
                )}
              </div>
            ) : null}
            <p>Content</p>
            <MDEditor value={values.content} onChange={handleMDEditorChange} />
          </div>
        </div>

        {/* This is the third row that contain the submit button */}
        <div className="row">
          <div style={{ height: "70px" }} />
          <div className="">
            <button
              className="btn btn-primary btn-block d-flex align-items-center justify-content-center"
              type="submit"
            >
              {isSubmitting ? (
                <CircularProgress
                  sx={{ color: white }}
                  size={24}
                  thickness={8}
                />
              ) : (
                "Submit content"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddContent;
