import React, { useEffect, useState } from "react";
import { get, deleteRequest } from "api";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";

interface Project {
  _id: string;
  slug: string;
  title: string;
  description: string;
  category: string;
}

const ListProject: React.FC = () => {
  const history = useHistory();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get("/projects")
      .then((response) => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
        setLoading(false);
      });
  }, []);

  const handleEditClick = (slug: string) => {
    history.push(`/dashboard/edit-project/${slug}`);
  };

  const handleDeleteClick = (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this project?"
    );
    if (confirmDelete) {
      deleteRequest(`/projects/${id}`)
        .then(() => {
          return get("/projects");
        })
        .then((response) => {
          setProjects(response.data);
        })
        .catch((error) => {
          console.error("Error deleting project:", error);
        });
    }
  };

  const handleRowClicked = (row: Project) => {
    history.push(`/dashboard/edit-project/${row.slug}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Title",
      selector: (row: Project) => row.title,
      sortable: true,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row: Project) => row.description,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row: Project) => row.category,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: Project) => (
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={() => handleEditClick(row.slug)}
            className="btn btn-warning me-2"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={() => handleDeleteClick(row._id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search project..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredProjects = projects.filter(
    (project) =>
      project.title.toLowerCase().includes(searchQuery) ||
      project.description.toLowerCase().includes(searchQuery) ||
      project.category.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="">
      <div className="w-100">
        <h3>Project List</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredProjects}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
            highlightOnHover
            pointerOnHover
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListProject;
