import { get, deleteRequest, put } from "api";
import { QueryOptions, useQuery, useMutation, useQueryClient } from "react-query";
import { IGetCohortList, IGetPartnerCohorts, IPartner } from "types/cohort";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import queryClient from "query-client";

export const usePartnersList = (options?: QueryOptions<IGetPartnerCohorts>) => {
  return useQuery<IGetPartnerCohorts>(
    ["cohort/partners-list"],
    () => get("/partners"),
    { ...(options || {}), cacheTime: 0, retry: false }
  );
};

export const useDeleteCohort = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (cohortId: string) => deleteRequest(`cohorts/${cohortId}`),
    { ...(options || {}) }
  );
};

export const useCohortList = (options?: QueryOptions<IGetCohortList>) => {
  return useQuery<IGetCohortList, any>(
    ["cohort/cohorts-list"],
    () => get("/cohorts"),
    { ...(options || {}), cacheTime: 0, retry: false }
  );
};

export const usePartnerCohort = (
  partnerId: string,
  options?: QueryOptions<IGetCohortList>
) => {
  return useQuery<IGetCohortList, any>(
    ["partners", partnerId, "cohort-list"],
    () => get(`/partners/${partnerId}/cohorts`),
    { ...(options || {}), cacheTime: 0, retry: false }
  );
};

export const usePartner = (
  partnerId: string,
  options?: QueryOptions<GenericAPIResponse<IPartner>>
) => {
  return useQuery<GenericAPIResponse<IPartner>, any>(
    ["partners", partnerId],
    () => get(`/partners/${partnerId}`),
    { ...(options || {}), cacheTime: 0, retry: false }
  );
};

export const useUpdatePartnersCohort = (
  options?: MutationOptions<GenericAPIResponse<IPartner>>
) => {
  return useMutation(
    (value: { partnerId: string; cohortId: string }) =>
      put(`partners/${value.partnerId}`, {
        defaultCohort: value.cohortId,
      }),
    {
      ...(options || {}),
      onSuccess: (response: GenericAPIResponse<IPartner>) => {
        const { data: partner } = response;
        queryClient.setQueryData(['partners', partner._id], response);
        options?.onSuccess?.(response);
      },
    }
  );
};
