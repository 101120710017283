import React from "react";
import Popover from "@mui/material/Popover";
import { ebb } from "config/colors";

interface ActionOptionProps {
  openActionOption: null | HTMLElement;
  setOpenActionOption: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  handleOpenResetModal: () => void;
  handleOpenSubscribeUserModal: () => void;
}

const ActionOption: React.FC<ActionOptionProps> = ({
  openActionOption,
  setOpenActionOption,
  handleOpenResetModal,
  handleOpenSubscribeUserModal,
}) => {
  const options = [
    { name: "Reset Password", handleClick: handleOpenResetModal },
    { name: "Subscribe User", handleClick: handleOpenSubscribeUserModal },
  ];

  const handleClose = () => {
    setOpenActionOption(null);
  };

  const open = Boolean(openActionOption);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={openActionOption}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="">
          {options.map((option, index) => (
            <div
              key={index}
              className="cursor-pointer text-13 p-2"
              onClick={option.handleClick}
              onMouseOver={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = ebb)
              }
              onMouseOut={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = "")
              }
            >
              {option.name}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default ActionOption;
