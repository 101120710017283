import React, { useState } from "react";
import { useMutation } from "react-query";
import { post } from "api";
import { IAddPartner } from "types/partner";
import { white } from "config/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

const initialPartnerFormValues: IAddPartner = {
  _id: "",
  name: "",
  address: "",
  mobile: "",
  email: "",
};

const AddPartner: React.FC = () => {
  const [formData, setFormData] = useState<IAddPartner>(
    initialPartnerFormValues
  );

  const { mutate, isLoading } = useMutation(
    (newPartner: IAddPartner) => post("/partners", newPartner),
    {
      onSuccess: () => {
        toast("Partner added", { type: "success" });
        setFormData(initialPartnerFormValues);
      },
      onError: (error: AxiosError) => {
        toast(error.message, { type: "error" });
      },
    }
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      mutate(formData);
    } catch (err) {}
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row">
          <div>
            <div className="form-floating mb-3">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
                className="form-control"
                placeholder="Name"
              />
              <label>Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                name="address"
                onChange={handleChange}
                value={formData.address}
                className="form-control"
                placeholder="Address"
              />
              <label>Address</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="number"
                name="mobile"
                onChange={handleChange}
                value={formData.mobile}
                className="form-control"
                placeholder="Mobile"
              />
              <label>Mobile</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                className="form-control"
                placeholder="Email"
              />
              <label>Email</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div>
            <button
              className="btn btn-primary btn-block w-100 d-flex align-items-center justify-content-center"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{ color: white }}
                  size={24}
                  thickness={8}
                />
              ) : (
                "Add Partner"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddPartner;
