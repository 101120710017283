import React, { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IListCohorts } from "types/cohort";
import { useDeleteCohort, useUpdatePartnersCohort } from "hooks/cohorts";
import { GenericAPIResponse } from "types/generic";
import { toast } from "react-toastify";
import CourseModal from "../Modal/CourseModal";
import CustomSwitch from "components/CustomSwitch";

interface ICohortListProps {
  cohortList: IListCohorts[] | [];
  refetch: () => any;
  loading: boolean;
  defaultCohort?: string;
  showDefaultAction?: boolean;
}

const ListCohort = ({
  cohortList,
  refetch,
  loading,
  defaultCohort,
  showDefaultAction = false,
}: ICohortListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [cohortCourseModal, setCohortCourseModal] = useState(false);
  const [cohortId, setCohortId] = useState("");
  const [currentDefaultCohort, setCurrentDefaultCohort] = useState<
    string | undefined
  >(defaultCohort);

  const history = useHistory();

  useEffect(() => {
    setCurrentDefaultCohort(defaultCohort);
  }, [defaultCohort]);

  const handleCloseModal = () => {
    setCohortCourseModal(false);
  };

  const handleEditClick = (code: string) => {
    history.push(`/dashboard/edit-cohort/${code}`);
  };

  // Handle Delete Cohort
  const { mutateAsync: deleteCohortRequest } = useDeleteCohort({
    onSuccess: (data: GenericAPIResponse<any>) => handleSuccessResponse(),
  });

  const { mutateAsync: setDefaultCohortRequest } = useUpdatePartnersCohort();

  const handleSuccessResponse = () => {
    // success toast
    toast("Cohorts deleted successfully!", { type: "success" });
    refetch();
  };

  const handleDeleteRequest = (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this cohort?"
    );
    if (confirmDelete) {
      deleteCohortRequest(id);
    }
  };

  const handleDefaultRequest = (row: IListCohorts) => {
    if (row._id === currentDefaultCohort) {
      return;
    }

    toast.promise(
      setDefaultCohortRequest({
        partnerId: row.partnerId,
        cohortId: row._id,
      })
        .then(() => {
          setCurrentDefaultCohort(row._id);
        })
        .catch(() => {}),
      {
        pending: "Setting as default...",
        success: "Set as default cohort!",
        error: "Failed to set default cohort. Please try again.",
      }
    );
  };

  const handleRowClicked = (row: IListCohorts) => {
    history.push(`/dashboard/edit-cohort/${row._id}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.toLowerCase();
    setSearchQuery(keyword);
  };

  const columns = [
    {
      name: "Name",
      selector: (row: IListCohorts) => row.name,
      sortable: true,
      grow: 1,
    },
    {
      name: "Status",
      selector: (row: IListCohorts) => row.status,
      sortable: true,
    },
    {
      name: "Members Count",
      selector: (row: IListCohorts) => row.membersCount,
      sortable: true,
    },
    {
      name: "Includable",
      selector: (row: IListCohorts) => (row.includable ? "true" : "false"),
      sortable: true,
    },
    showDefaultAction && {
      name: "Default",
      cell: (row: IListCohorts) => (
        <CustomSwitch
          checked={row._id === currentDefaultCohort}
          onChange={() => handleDefaultRequest(row)}
        />
      ),
    },
    {
      name: "Action",
      grow: 2,
      cell: (row: IListCohorts) => (
        <div
          className="btn-group text-nowrap"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            onClick={() => handleEditClick(row._id)}
            className="btn btn-warning me-2 "
            style={{ fontSize: "14px" }}
          >
            Edit
          </button>

          <button
            type="button"
            onClick={() => handleDeleteRequest(row._id)}
            className="btn btn-danger me-2"
            style={{ fontSize: "14px" }}
          >
            Delete
          </button>

          <button
            type="button"
            className="btn btn-success me-2"
            onClick={() => {
              setCohortCourseModal(true);
              setCohortId(row._id);
            }}
            style={{ fontSize: "14px" }}
          >
            Add Course
          </button>

          <CourseModal
            handleCloseModal={handleCloseModal}
            cohortCourseModal={cohortCourseModal}
            cohortId={cohortId}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ].filter(Boolean) as TableColumn<IListCohorts>[];

  const customStyles = {
    headRow: {
      style: {
        minWidth: "100%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    search: {
      style: {
        position: "relative",
        width: "100%",
      },
    },
    searchInput: {
      style: {
        width: "100%",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center w-100">
        <input
          type="text"
          placeholder="Search cohorts..."
          onChange={handleSearch}
          className="form-control me-2"
          value={searchQuery}
        />
        <div className="cursor-pointer" onClick={() => setSearchQuery("")}>
          <RefreshIcon />
        </div>
      </div>
    );
  }, [searchQuery]);

  const filteredCohorts = cohortList.filter(
    (cohorts) =>
      cohorts.name.toLowerCase().includes(searchQuery) ||
      cohorts.status.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="">
      <div className="w-100">
        <h3>Cohorts List</h3>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <DataTable
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredCohorts}
            columns={columns}
            pagination
            paginationPerPage={10}
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
            highlightOnHover
            pointerOnHover
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListCohort;
