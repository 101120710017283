export interface ILoginFormValues {
  phoneOrEmail?: string;
  password?: string;
}

export type IAuthData = {
  token: string;
  account?: IAccount;
};

export type IAccount = {
  user_id: number;
  name: string;
  email: string;
  role: IAccountRole;
};

export enum IAccountRole {
  STUDENT = "student",
  ADMIN = "admin",
}
