import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { accent, primary } from "config/colors";
import { getToken } from "api/cookie";

/** Appbar for the home page */
const Appbar = () => {
  const authToken = getToken();
  const hasAuthToken = authToken && authToken.trim().length > 0;

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        boxShadow: "0 3px 6px rgb(0 0 0 / 12%)",
      }}
    >
      <Toolbar
        sx={{
          background: "#fff",
          color: "#000",
          elevation: 0,
          minHeight: "64px !important",
        }}
      >
        <Box textAlign="right" flex={1}>
          <StyledNavLink to="/signin">Login</StyledNavLink>
          {hasAuthToken && (
            <StyledNavLink to="/dashboard/home">Dashboard</StyledNavLink>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const StyledNavLink = styled(NavLink)`
  margin-left: 16px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-decoration: none !important;
  color: ${accent} !important;
  &:hover {
    color: ${primary} !important;
  }
`;

export default Appbar;
